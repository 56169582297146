import React from "react";
import styled from "@emotion/styled";
import { ManufacturerLayout } from "./Layout";
import CreateManuProfile from "../ProfileCreate/CreateManuProfile";

const ContentContainer = styled.div`
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
`;

export const ManufacturerCreateProfile = () => {
  return (
    <ManufacturerLayout>
      <ContentContainer>
        <CreateManuProfile />
      </ContentContainer>
    </ManufacturerLayout>
  );
};

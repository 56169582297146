import React, { useState } from "react";
import styled from "@emotion/styled";

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
`;

const TabButton = styled.button`
  background-color: ${({ isActive }) => (isActive ? "#e6efff" : "#fff")};
  color: ${({ isActive }) => (isActive ? "#003188" : "#000")};
  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
  border-radius: 8px 8px 0 0;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: ${({ isActive }) =>
    isActive ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none"};
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e6efff;
  }
`;

const TabContent = styled.div`
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Tabs = ({ labels, children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TabContainer>
      <TabHeader>
        {labels.map((label, index) => (
          <TabButton
            key={index}
            isActive={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            {label}
          </TabButton>
        ))}
      </TabHeader>
      <TabContent>{children[activeTab]}</TabContent>
    </TabContainer>
  );
};

import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ProductCard } from "./ProductCard";
import { _get } from "../../api/apiClient";
import { Alert } from "../UIElements";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
`;

const NoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
`;

export const RFQList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // useEffect(() => {
  //   const getRfqList = async () => {
  //     try {
  //       const { data } = await _get("/rfqs");
  //       setProducts(data);
  //     } catch (error) {
  //       console.error("get rfq list:", error);
  //       setErrorMessage(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   getRfqList();
  // }, []);

  if (loading) return <div>Loading...</div>;

  if (products.length === 0) {
    return <NoListContainer>No RFQ's available.</NoListContainer>;
  }

  return (
    <div>
      {errorMessage && (
        <MessageContainer>
          <Alert type="error" message={errorMessage} />
        </MessageContainer>
      )}

      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

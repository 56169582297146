import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  InputBox,
  InputTextArea,
  PrimaryButton,
  SecondaryButton,
  SelectInputBox,
} from "../../UIElements";
import { SubTitle } from "../../UIElements/Text";
import { RFQImage } from "./RFQImage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  & div {
    flex: 1;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const Checkbox = styled.input`
  margin-right: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const StyledLink = styled(Link)`
  color: #285de7;
  text-decoration: none;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-direction: flex-start;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const validationSchema = Yup.object().shape({
  productName: Yup.string().required("Required"),
  productQuantity: Yup.string().required("Required"),
  // terms: Yup.bool().oneOf([true], "Must accept terms and conditions"),
  // productCode: Yup.string().required("Required"),
  // productMaterial: Yup.string().required("Required"),
  // manufacturingProcess: Yup.string().required("Required"),
  // manufacturingSubProcess: Yup.string().required("Required"),
  // finishingProcess: Yup.string().required("Required"),
  // productAdditionalInformation: Yup.string().required("Required"),
});

export const ProductDetailsRfq = (props) => {
  const navigate = useNavigate();

  const handleSubmit = async (formValues) => {
    props.submitProductDetailsInfo(formValues);
    props.handleNext();
  };

  const goToHome = (event) => {
    event.preventDefault();
    navigate("/buyer/search");
  };

  // const addProduct = (event) => {
  //   event.preventDefault();
  // };

  return (
    <Container>
      <SubTitle>Product details</SubTitle>
      <Formik
        enableReinitialize
        initialValues={props.productDetailsInfo}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          isSubmitting,
        }) => (
          <Form>
            <RFQImage label="Product file (Supported file types)" isRequired />
            <InputRow>
              <InputBox
                name="productName"
                label="Product name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productName}
                isInvalid={touched.productName && errors.productName}
                errorMessage={touched.productName && errors.productName}
                placeholderText="Product name"
                isRequired
              />
              <InputBox
                name="productCode"
                label="Product code"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productCode}
                isInvalid={touched.productCode && errors.productCode}
                errorMessage={touched.productCode && errors.productCode}
              />
            </InputRow>
            <InputRow>
              <SelectInputBox
                name="productMaterial"
                label="Product material"
                value={values.productMaterial}
                defaultValue={values.productMaterial}
                onSelectionChange={handleChange}
                onBlur={handleBlur}
                touched={touched.productMaterial}
                isInvalid={touched.productMaterial && errors.productMaterial}
                errorMessage={touched.productMaterial && errors.productMaterial}
                defaultOption=""
                optionList={[]}
              />
              <SelectInputBox
                name="manufacturingProcess"
                label="Manufacturing process"
                value={values.manufacturingProcess}
                defaultValue={values.manufacturingProcess}
                onSelectionChange={handleChange}
                onBlur={handleBlur}
                touched={touched.manufacturingProcess}
                isInvalid={
                  touched.manufacturingProcess && errors.manufacturingProcess
                }
                errorMessage={
                  touched.manufacturingProcess && errors.manufacturingProcess
                }
                defaultOption=""
                optionList={[]}
              />
            </InputRow>
            <InputRow>
              <SelectInputBox
                name="manufacturingSubProcess"
                label="Manufacturing sub process"
                value={values.manufacturingSubProcess}
                defaultValue={values.manufacturingSubProcess}
                onSelectionChange={handleChange}
                onBlur={handleBlur}
                touched={touched.manufacturingSubProcess}
                isInvalid={
                  touched.manufacturingSubProcess &&
                  errors.manufacturingSubProcess
                }
                errorMessage={
                  touched.manufacturingSubProcess &&
                  errors.manufacturingSubProcess
                }
                defaultOption=""
                optionList={[]}
              />
              <SelectInputBox
                name="finishingProcess"
                label="Finishing process"
                value={values.finishingProcess}
                defaultValue={values.finishingProcess}
                onSelectionChange={handleChange}
                onBlur={handleBlur}
                touched={touched.finishingProcess}
                isInvalid={touched.finishingProcess && errors.finishingProcess}
                errorMessage={
                  touched.finishingProcess && errors.finishingProcess
                }
                defaultOption=""
                optionList={[]}
              />
            </InputRow>
            <InputRow>
              <SelectInputBox
                name="productCertifications"
                label="Product certifications needed"
                value={values.productCertifications}
                defaultValue={values.productCertifications}
                onSelectionChange={handleChange}
                onBlur={handleBlur}
                touched={touched.productCertifications}
                isInvalid={
                  touched.productCertifications && errors.productCertifications
                }
                errorMessage={
                  touched.productCertifications && errors.productCertifications
                }
                defaultOption=""
                optionList={[]}
              />
              <InputBox
                name="productQuantity"
                label="Product quantity"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productQuantity}
                isInvalid={touched.productQuantity && errors.productQuantity}
                errorMessage={touched.productQuantity && errors.productQuantity}
                placeholderText=""
                isRequired
              />
            </InputRow>
            <InputTextArea
              name="productAdditionalInformation"
              label="Additional information about the product"
              type="textarea"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.productAdditionalInformation}
              isInvalid={
                touched.productAdditionalInformation &&
                errors.productAdditionalInformation
              }
              errorMessage={
                touched.productAdditionalInformation &&
                errors.productAdditionalInformation
              }
              placeholderText="Add information here"
            />
            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                name="terms"
                checked={values.terms}
                onChange={handleChange}
                invalid={touched.terms && errors.terms}
              />
              <label htmlFor="terms">
                Apply standard
                <StyledLink
                  to={`/terms-of-service`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Raprod NDA terms
                </StyledLink>
                to protect your files, designs and other intellectual property
                associated with your product.
              </label>
            </CheckboxWrapper>
            <ButtonWrapper>
              {/* <SecondaryButton onClick={addProduct}>
                Add Products
              </SecondaryButton> */}
              <SecondaryButton onClick={goToHome}>Cancel</SecondaryButton>
              <PrimaryButton> Next</PrimaryButton>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

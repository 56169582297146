import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import {
  fetchAuthSession,
  fetchUserAttributes,
  signIn,
  signInWithRedirect,
  AuthError,
  signOut,
} from "aws-amplify/auth";
import siteLogo from "../../../Assets/svg/logo.svg";
import googleLogo from "../../../Assets/svg/googleLogo.svg";
import facebookLogo from "../../../Assets/svg/facebookLogo.svg";
import linkedInLogo from "../../../Assets/svg/linkedInLogo.svg";
import { Alert, Button, InputBox, PasswordInput } from "../../../UIElements";
import { EmailValidator, PasswordValidator } from "../../../../helpers/utils";
import { Header } from "../../../Landing/Header";
import { SiteFooter } from "../../../Landing/Footer";

const Container = styled.div`
  background-color: #f1f7ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 32px;
  text-align: center;
  @media (min-width: 500px) {
    width: 400px;
  }
`;

const MainLogo = styled.img`
  margin-bottom: 16px;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
  }

  &::before {
    margin-right: 0.25em;
  }

  &::after {
    margin-left: 0.25em;
  }
`;

const SocialLogos = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 24px;

  img {
    cursor: pointer;
  }
`;

const Text = styled.p`
  color: #001d32;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  color: #001d32;
  text-decoration: none;
  margin-left: 8px;
  font-weight: 500;
`;

export const LoginCard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogout = async () => {
    try {
      await signOut();
      signInUser();
    } catch (error) {
      console.log("sign out error:", error);
      setErrorMessage(error.message);
    }
  };

  const signInUser = async () => {
    try {
      const user = await signIn({
        username: email,
        password: password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH",
        },
      });

      // if challengeName is  NEW_PASSWORD_REQUIRED, amplify does not persist session , ask user to update the password.
      // navaigate("/updatePassword");
      const session = await fetchAuthSession();

      const userAttributes = await fetchUserAttributes();

      const accessToken = session.tokens?.accessToken.toString();
      localStorage.setItem("accessToken", accessToken);
      setErrorMessage("");
      // if user type is Buyer, navigate to buyer flow
      if (userAttributes["custom:user_type"] === "buyer") {
        navigate("/buyer/profile");
      } else {
        navigate("/home");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("sign in error: ", error);
      if (
        error instanceof AuthError &&
        error.name === "UserAlreadyAuthenticatedException"
      ) {
        localStorage.clear();
        handleLogout();
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (!EmailValidator(email)) {
      return setEmailError("Please enter a valid email address");
    }
    if (!PasswordValidator(password)) {
      setEmailError("");
      return setPasswordError(
        "Password must be at least eight characters, include one uppercase letter, one lowercase letter, one number, and one special character."
      );
    }
    setIsLoading(true);
    setEmailError("");
    setPasswordError("");
    signInUser();
  };

  const handleGoogleLogin = () => {
    signInWithRedirect({ provider: "Google" });
  };

  return (
    <>
      <Header />
      <Container>
        <Card>
          <MainLogo src={siteLogo} alt="raprod Logo" />
          {errorMessage && <Alert type="error" message={errorMessage} />}
          <form onSubmit={handleLogin}>
            <InputBox
              type="email"
              onTextChange={setEmail}
              isInvalid={emailError}
              errorMessage={emailError}
              placeholderText="email"
            />
            <PasswordInput
              onTextChange={setPassword}
              isInvalid={passwordError}
              errorMessage={passwordError}
              style={{ marginTop: "16px", marginBottom: "32px " }}
            />
            <Button isLoading={isLoading}>
              {isLoading ? "Log In..." : "Log In"}
            </Button>
          </form>
          <Text>
            Forgot your password?{" "}
            <StyledLink to={"/resetPassword"}>reset password</StyledLink>
          </Text>
          <Divider>or</Divider>
          <SocialLogos>
            <img
              src={googleLogo}
              alt="google login"
              onClick={handleGoogleLogin}
            />
            <img src={facebookLogo} alt="facebook login" />
            <img src={linkedInLogo} alt="linkedin login" />
          </SocialLogos>
          <Text>
            Don't have an account?{" "}
            <StyledLink to={"/signUpOptions"}>Register</StyledLink>
          </Text>
        </Card>
      </Container>
      <SiteFooter />
    </>
  );
};

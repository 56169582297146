import React from "react";
import styled from "@emotion/styled";
import { Options } from "./SignUp/Options";
import { Header } from "../Landing/Header";
import { SiteFooter } from "../Landing/Footer";

// Styled components
const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const Title = styled.h1`
  color: #003188;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  margin-top: 0;
`;

const Text = styled.p`
  color: #003188;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  margin: 0 0 50px 0;
`;

export const SignUpOptions = () => (
  <>
    <Header />
    <Container>
      <Title>Register and transform your business with Raprod</Title>
      <Text>
        Ready to take the next step? Select whether you're a buyer or a
        manufacturer to begin your journey with Raprod!
      </Text>
      <Options />
    </Container>
    <SiteFooter />
  </>
);

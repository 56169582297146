import React, { useState } from "react";
import styled from "@emotion/styled";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

// Styled components
const Container = styled.div`
  background-color: #f5f9ff;
  padding: 16px 16px 16px 0px;
  margin: 8px 0;
  border-radius: 4px;
`;
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Question = styled.div`
  color: #003188;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
`;

const Answer = styled.div`
  color: #003188;
  margin-top: 16px;
  padding-left: 36px;
  white-space: preserve-breaks;
`;

const QuestionAnswerBlock = ({ question, answer, isOpen, onClick }) => {
  return (
    <Container>
      <QuestionContainer>
        {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        <Question onClick={onClick} isOpen={isOpen}>
          {question}
        </Question>
      </QuestionContainer>
      {isOpen && <Answer>{answer}</Answer>}
    </Container>
  );
};

export const QuestionAnswerList = ({ qaList }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      {qaList.map((qa, index) => (
        <QuestionAnswerBlock
          key={index}
          question={qa.question}
          answer={qa.answer}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

import React from "react";
import styled from "@emotion/styled";
import { FaCheck } from "react-icons/fa";

const StepperWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  width: 95%;

  @media (max-width: 600px) {
    justify-content: space-around;
  }
`;

const StepCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${({ completed, active }) =>
    completed ? "#fff" : active ? "#003188" : "#545454"};
  background-color: ${({ completed, active }) =>
    completed ? "#003188" : active ? "#fff" : "#D9D9D9"};
  border: 2px solid
    ${({ completed, active }) =>
      completed ? "#003188" : active ? "#003188" : "#D9D9D9"};
  transition: all 0.3s ease;
`;

const StepText = styled.div`
  margin-left: 10px;
  font-size: 14px;
  color: ${({ completed, active }) =>
    completed ? "#003188" : active ? "#003188" : "#545454"};
  transition: color 0.3s ease;

  @media (max-width: 600px) {
    display: none;
  }
`;

const DottedLine = styled.div`
  flex-grow: 1;
  height: 2px;
  border-bottom: 2px dotted #d9d9d9;
  margin: 0 10px;

  @media (max-width: 600px) {
    margin: 0 5px;
  }
`;

const Step = ({ stepNumber, stepName, isActive, isCompleted }) => (
  <>
    <StepCircle active={isActive} completed={isCompleted}>
      {isCompleted ? <FaCheck size={14} /> : stepNumber}
    </StepCircle>
    <StepText active={isActive} completed={isCompleted}>
      {stepName}
    </StepText>
  </>
);

export const RFQStepper = ({ activeStep = 1 }) => {
  return (
    <StepperWrapper>
      <Step
        stepNumber="1"
        stepName="Product details"
        isActive={activeStep === 1}
        isCompleted={activeStep > 1}
      />
      <DottedLine />
      <Step
        stepNumber="2"
        stepName="Quote details"
        isActive={activeStep === 2}
        isCompleted={activeStep > 2}
      />
      <DottedLine />
      <Step
        stepNumber="3"
        stepName="Shipping details"
        isActive={activeStep === 3}
        isCompleted={activeStep > 3}
      />
    </StepperWrapper>
  );
};

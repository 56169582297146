import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { PrimaryButton } from "../UIElements/Button";

// Styled components
const Container = styled.div`
  background-color: #ffe9e9;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  margin: 0 20px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
`;
const SmallText = styled.div`
  margin: 10px 0;
  font-size: 12px;
  font-weight: 400;
`;

const ButtonRow = styled.div`
  margin-top: 20px;
`;

export const IncompleteProfile = () => {
  const navigate = useNavigate();
  const goToProfile = () => navigate("/home/manuProfileCreate");

  return (
    <Container>
      <Text>Profile Incomplete</Text>
      <SmallText>Complete your profile so that buyers can find you.</SmallText>
      <ButtonRow>
        <PrimaryButton onClick={goToProfile}>Complete Profile</PrimaryButton>
      </ButtonRow>
    </Container>
  );
};

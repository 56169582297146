import React from "react";
import styled from "@emotion/styled";
import { ManufacturerLayout } from "./Layout";
import ProfileManufacture from "../Profile/Manufacture/ProfileManufacture";

const Spacer = styled.div`
  margin-top: 50px;
`;

export const ManufacturerProfile = () => {
  return (
    <ManufacturerLayout>
      <Spacer />
      <ProfileManufacture />
    </ManufacturerLayout>
  );
};

import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const PrivateRoutes = () => {
  const [userId, setUserId] = useState("loading");
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      try {
        const session = await fetchAuthSession();

        setUserId(session?.tokens?.accessToken || "");
      } catch (error) {
        setUserId("");
      }
    };

    getSession();
  }, [navigate]);

  if (userId === "loading") {
    return <></>;
  }

  if (userId === "") {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoutes;

// validationSchema.js
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  productDetails: Yup.string().required("Required"),
  address1: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  zipcode: Yup.string().required("Required"),
});

import React from "react";
import styled from "@emotion/styled";
import { ManufacturerLayout } from "./Layout";
import DirectoryContainer from "../Directory/DirectoryContainer";

const Spacer = styled.div`
  margin-top: 50px;
`;

export const ManufacturerDirectory = () => {
  return (
    <ManufacturerLayout>
      <Spacer />
      <DirectoryContainer />
    </ManufacturerLayout>
  );
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { BuyerLayout } from "./Layout";
import { Alert, PrimaryButton } from "../UIElements";
import { PageTitle, SubTitle } from "../UIElements/Text";
import { ProductDetailsRfq } from "./BuyerRFQ/ProductDetails";
import { ShippingDetailsRfq } from "./BuyerRFQ/ShippingDetails";
import { QuoteDetailsRfq } from "./BuyerRFQ/QuoteDetails";
import { RFQStepper } from "./BuyerRFQ/RfqSteps";
import { useBuyerRfq } from "./BuyerRFQ/useBuyerRfq";
import { Accordion } from "../common/Accordion";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
`;

export const BuyerRFQs = () => {
  const navigate = useNavigate();
  const { handleSubmit, errorMessage, isBuyerRfqSuccess } = useBuyerRfq();

  const [activeStep, setActiveStep] = useState(0);
  const [productDetailsInfo, setProductDetailsInfo] = useState({
    productName: "",
    productCode: "",
    productQuantity: "",
    productMaterial: "",
    manufacturingProcess: "",
    manufacturingSubProcess: "",
    finishingProcess: "",
    productCertifications: "",
    productAdditionalInformation: "",
    terms: false,
  });
  const [quoteDetailsInfo, setQuoteDetailsInfo] = useState({
    productRfqName: "",
    quotedNeededByDate: "",
    deliveryDate: "",
    manufacturingLocation: "",
    shippingPaidBy: "",
  });
  const [shippingInfo] = useState({
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    connectTerms: false,
    successStory: false,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFormSubmit = async (shippingDetails) => {
    handleSubmit({
      productDetailsInfo,
      quoteDetailsInfo,
      shippingDetails,
    });
  };

  const goToHome = (event) => {
    event.preventDefault();
    navigate("/buyer/search");
  };

  return (
    <BuyerLayout>
      <Container>
        <PageTitle>RFQs</PageTitle>
        {errorMessage && (
          <MessageContainer>
            <Alert type="error" message={errorMessage} />
          </MessageContainer>
        )}
        <RFQStepper activeStep={activeStep + 1} />
        {isBuyerRfqSuccess && !errorMessage ? (
          <MessageContainer>
            <Alert
              type="success"
              message={
                <>
                  <SubTitle>RFQ successful.</SubTitle>
                  <PrimaryButton onClick={goToHome}>Go to Search</PrimaryButton>
                </>
              }
            />
          </MessageContainer>
        ) : (
          <ContentContainer>
            <Accordion
              header="Product details"
              openBlock={
                <ProductDetailsRfq
                  handleBack={handleBack}
                  handleNext={handleNext}
                  submitProductDetailsInfo={setProductDetailsInfo}
                  productDetailsInfo={productDetailsInfo}
                />
              }
              isOpen={activeStep === 0}
            />
            <Accordion
              header="Quote details"
              openBlock={
                <QuoteDetailsRfq
                  handleBack={handleBack}
                  handleNext={handleNext}
                  submitQuoteDetailsInfo={setQuoteDetailsInfo}
                  quoteDetailsInfo={quoteDetailsInfo}
                />
              }
              isOpen={activeStep === 1}
            />
            <Accordion
              header="Shipping details"
              openBlock={
                <ShippingDetailsRfq
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleFormSubmit={handleFormSubmit}
                  shippingInfo={shippingInfo}
                />
              }
              isOpen={activeStep === 2}
            />
          </ContentContainer>
        )}
      </Container>
    </BuyerLayout>
  );
};

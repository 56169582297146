import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { GoLocation } from "react-icons/go";
import { FaRegStar } from "react-icons/fa";

import { BuyerLayout } from "./Layout";
import { _get } from "../../api/apiClient";
import { PrimaryButton, SecondaryButton } from "../UIElements";

const ProfileCard = styled.div`
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px;
  position: relative;
  display: grid;
`;
const ProfileHeader = styled.div`
  margin-top: 20px;
`;

const CompanyTitle = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const CompanySubTitle = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Ratings = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  gap: 4px;
`;

const ProfileButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
`;
const Card = styled.div`
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px;
`;

const Title = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;
  font-family: Montserrat;
`;

const LocationIcon = styled.div`
  color: #3f76bf;
  font-family: Montserrat;
`;
const Address = styled.div`
  color: var(--Text, #001d32);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-left: 3px;
  font-family: Montserrat;
`;

export const BuyerManufactureProfile = () => {
  const [manuProfile, setManuProfile] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ManufacturerId = queryParams.get("manufacturerId");

  const fetchProfile = async (ManufacturerId) => {
    const apiUrl =
      ManufacturerId == null
        ? "/manufacturers"
        : `/manufacturers/${ManufacturerId}`;
    try {
      const response = await _get(apiUrl);
      setManuProfile(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Buyer Fetch  manufacturer profile Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile(ManufacturerId);
  }, [ManufacturerId]);

  return (
    <BuyerLayout>
      {loading ? (
        <div>loading</div>
      ) : (
        <div>
          <ProfileCard>
            <ProfileHeader>
              <CompanyTitle>{manuProfile?.ManufacturerName}</CompanyTitle>
              <CompanySubTitle>Manufacturer</CompanySubTitle>
              <Ratings>
                <FaRegStar color={"#000000"} />
                5.0 rating
              </Ratings>
              <ProfileButtonContainer>
                <PrimaryButton>Connect</PrimaryButton>
                <SecondaryButton>Mark favorite</SecondaryButton>
              </ProfileButtonContainer>
            </ProfileHeader>
          </ProfileCard>

          <Card>
            <Title>Address</Title>
            <ContentWrapper>
              <LocationIcon>
                <GoLocation size={18} color="#3f76bf" />
              </LocationIcon>
              <Address>
                {manuProfile?.AddressLineOne}{" "}
                {manuProfile?.City ? `, ${manuProfile?.City}` : ""}
                {manuProfile?.State ? `, ${manuProfile?.State}` : ""}
                {manuProfile?.Country ? `, ${manuProfile?.Country}` : ""}
              </Address>
            </ContentWrapper>
          </Card>
          <Card style={{ marginBottom: "80px" }}>
            <Title>About</Title>
            <ContentWrapper>
              <Address>{manuProfile?.Description}</Address>
            </ContentWrapper>
          </Card>
        </div>
      )}
    </BuyerLayout>
  );
};

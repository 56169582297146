import { useState } from "react";
import { _post } from "../../../api/apiClient";

export const useBuyerRfq = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isBuyerRfqSuccess, setIsBuyerRfqSuccess] = useState(false);

  const handleSubmit = async (formValues) => {
    const buyerRfqValues = mapFormValues(formValues);

    const result = JSON.parse(JSON.stringify(buyerRfqValues), (key, value) =>
      value === "" ? null : value
    );
    console.log("create buyer rfq values:", result);
    setIsBuyerRfqSuccess(true);

    // try {
    //   await _post("/manufacturers", result);
    //   setErrorMessage("");
    //   setIsBuyerRfqSuccess(true);
    // } catch (error) {
    //   setErrorMessage(error.message);
    //   console.error("create buyer rfq error:", error);
    // }
  };

  return {
    handleSubmit,
    errorMessage,
    isBuyerRfqSuccess,
  };
};

const mapFormValues = (formValues) => ({
  productName: formValues.productDetailsInfo?.productName,
  productCode: formValues.productDetailsInfo?.productCode,
  productQuantity: formValues.productDetailsInfo?.productQuantity,
  productMaterial: formValues.productDetailsInfo?.productMaterial,
  manufacturingProcess: formValues.productDetailsInfo?.manufacturingProcess,
  manufacturingSubProcess:
    formValues.productDetailsInfo?.manufacturingSubProcess,
  finishingProcess: formValues.productDetailsInfo?.finishingProcess,
  productCertifications: formValues.productDetailsInfo?.productCertifications,
  productAdditionalInformation:
    formValues.productDetailsInfo?.productAdditionalInformation,
  terms: false,
  productRfqName: formValues.quoteDetailsInfo?.productRfqName,
  quotedNeededByDate: formValues.quoteDetailsInfo?.quotedNeededByDate,
  deliveryDate: formValues.quoteDetailsInfo?.deliveryDate,
  manufacturingLocation: formValues.quoteDetailsInfo?.manufacturingLocation,
  shippingPaidBy: formValues.quoteDetailsInfo?.shippingPaidBy,
  ShippingInfo: {
    AddressLineOne: formValues.shippingDetails?.address1,
    AddressLineTwo: formValues.shippingDetails?.address2,
    City: formValues.shippingDetails?.city,
    State: formValues.shippingDetails?.state,
    Country: formValues.shippingDetails?.country,
    ZipCode: formValues.shippingDetails?.zipcode,
  },
});

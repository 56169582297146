import React from "react";
import ReactDOM from "react-dom/client";
// import { Authenticator } from '@aws-amplify/ui-react';
// import QueryProvider from "./libs/contexts/QueryProvider";
import "./index.css";
//import App from "./App";
import "./Fonts/Montserrat-Italic-VariableFont_wght.ttf";
import "./Fonts/Montserrat-VariableFont_wght.ttf";
import reportWebVitals from "./reportWebVitals";

// Import from the route
import Main from "./Router/Main";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <QueryProvider> */}
    {/* <Authenticator> */}
    <Main />
    {/* </Authenticator> */}
    {/* </QueryProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

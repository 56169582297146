import React, { useEffect, useState } from "react";
import styles from "./ProfileManufacture.module.css";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { GoLocation } from "react-icons/go";
import Rating from "@mui/material/Rating";
import manulogo from "../../Assets/img/logoRap.png";
import { useLocation } from "react-router-dom";
import { _get } from "../../../api/apiClient";

export default function ProfileManufacture() {
  const [manuProfile, setManuProfile] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ManufacturerId = queryParams.get("ManufacturerId");
  // const token = localStorage.getItem("accessToken");

  const fetchProfile = async (ManufacturerId) => {
    const apiUrl =
      ManufacturerId == null
        ? "/manufacturers"
        : `/manufacturers/${ManufacturerId}`;
    try {
      const response = await _get(apiUrl);
      setManuProfile(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
    // axios
    //   .get(apiUrl, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   })
    //   .then((response) => {
    //     // Handle success, you can access the response data using `response.data`
    //     //setSearchResults(response.data.results);
    //     console.log("Response:", response);
    //     setManuProfile(response.data);
    //   })
    //   .catch((error) => {
    //     // Handle error, you can access the error details using `error`
    //     console.error("Error:", error);
    //   })
    //   .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchProfile(ManufacturerId);
  }, [ManufacturerId]);

  return (
    <>
      {loading ? (
        <div>loading</div>
      ) : (
        <div>
          <div className={styles.profileCard}>
            <div className={styles.imgDiv}>
              {/* <img
            src={manulogo}
            alt="logo-manuf-profile"
            className={styles.companyLogo}
          /> */}
              <Avatar
                alt="Remy Sharp"
                src={manulogo}
                sx={{ width: 120, height: 120 }}
                className={styles.companyLogo}
              />
            </div>
            <div className={styles.profileHeader}>
              <div className={styles.companyTitle}>
                {manuProfile?.ManufacturerName}
              </div>
              <div className={styles.companySubTitle}>Manufacturer</div>
              <div className={styles.ratingsWrapper}>
                <Rating
                  name="read-only"
                  value={"4"}
                  readOnly
                  className={styles.rating}
                />
              </div>
              <div className={styles.profileButtonContainer}>
                <button className={styles.profileButton}>Connect</button>
                <button className={styles.profileButton}>Mark favorite</button>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.title}>Address</div>
            <div className={styles.contentWrapper}>
              <GoLocation className={styles.locationIcon} size={18} />
              <div className={styles.address}>
                {manuProfile?.AddressLineOne}, {manuProfile?.AddressLineTwo}
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>About</div>
            <div className={styles.contentWrapper}>
              <div className={styles.address}>{manuProfile?.Description}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  InputBox,
  InputDate,
  PrimaryButton,
  SecondaryButton,
  SelectInputBox,
} from "../../UIElements";
import { SubTitle } from "../../UIElements/Text";
import { COUNTRY_LIST } from "../../../constants/countries";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  & div {
    flex: 1;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-direction: flex-start;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const validationSchema = Yup.object().shape({
  productRfqName: Yup.string().required("Required"),
  quotedNeededByDate: Yup.string().required("Required"),
  deliveryDate: Yup.string().required("Required"),
});

export const QuoteDetailsRfq = (props) => {
  const handleSubmit = async (formValues) => {
    props.submitQuoteDetailsInfo(formValues);
    props.handleNext();
  };

  return (
    <Container>
      <SubTitle>Quote details</SubTitle>
      <Formik
        enableReinitialize
        initialValues={props.quoteDetailsInfo}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          isSubmitting,
        }) => (
          <Form>
            <InputRow>
              <InputBox
                name="productRfqName"
                label="Product RFQ name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productRfqName}
                isInvalid={touched.productRfqName && errors.productRfqName}
                errorMessage={touched.productRfqName && errors.productRfqName}
                placeholderText=""
                isRequired
              />
            </InputRow>

            <InputRow>
              <InputDate
                name="quotedNeededByDate"
                label="Quote needed by date"
                value={values.quotedNeededByDate}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.quotedNeededByDate}
                isInvalid={
                  touched.quotedNeededByDate && errors.quotedNeededByDate
                }
                errorMessage={
                  touched.quotedNeededByDate && errors.quotedNeededByDate
                }
                isRequired
              />
              <InputDate
                name="deliveryDate"
                label="Product delivery needed by date"
                value={values.deliveryDate}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.deliveryDate}
                isInvalid={touched.deliveryDate && errors.deliveryDate}
                errorMessage={touched.deliveryDate && errors.deliveryDate}
                isRequired
              />
            </InputRow>
            <InputRow>
              <SelectInputBox
                name="manufacturingLocation"
                label="Product manufacturing location"
                value={values.manufacturingLocation}
                defaultValue={values.manufacturingLocation}
                onSelectionChange={handleChange}
                onBlur={handleBlur}
                touched={touched.manufacturingLocation}
                isInvalid={
                  touched.manufacturingLocation && errors.manufacturingLocation
                }
                errorMessage={
                  touched.manufacturingLocation && errors.manufacturingLocation
                }
                defaultOption="Select your country"
                optionList={COUNTRY_LIST}
              />
              <InputBox
                name="shippingPaidBy"
                label="Shipping cost paid by"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shippingPaidBy}
                isInvalid={touched.shippingPaidBy && errors.shippingPaidBy}
                errorMessage={touched.shippingPaidBy && errors.shippingPaidBy}
                placeholderText=""
              />
            </InputRow>
            <ButtonWrapper>
              <SecondaryButton onClick={props.handleBack}>Back</SecondaryButton>
              {/* <SecondaryButton>Cancel</SecondaryButton> */}
              <PrimaryButton>Next</PrimaryButton>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Header } from "../Landing/Header";
import { SiteFooter } from "../Landing/Footer";
import { PageTitle, RegularText } from "../UIElements/Text";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 50px 20px;
`;

const CenterContainer = styled.div`
  display: flex;
  align-self: center;
`;

const PrivacyTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

const TextBold = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  color: #285de7;
  text-decoration: none;
  font-weight: 500;
`;

export const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Container>
        <CenterContainer>
          <PageTitle>Privacy Policy</PageTitle>
        </CenterContainer>
        <RegularText>
          Raprod Privacy Policy Last updated: August 8, 2024
        </RegularText>
        <RegularText>
          <PrivacyTitle>Introduction</PrivacyTitle> Raprod Inc. (“Raprod”)
          operates a B2B e-commerce platform connecting product manufacturers to
          buyers (the “Platform”), accessible through our websites, raprod.io
          and raprodplatform.com (the “Sites”). This Privacy Policy outlines how
          Raprod collects, uses, and protects your personal information when you
          access and use the Platform and Services (collectively “Raprod
          Services” or “Services”). By using Raprod and/or the Services, you
          agree to the collection and use of information in accordance with this
          policy.
        </RegularText>
        <RegularText>
          <PrivacyTitle>1. Information Collection</PrivacyTitle>We collect
          various types of information in connection with your use of the
          Platform, including:{" "}
          <ul>
            <li>
              Personal Information: When you create an account, we collect
              personal details such as your name, email address, phone number,
              and payment information.
            </li>
            <li>
              Business Information: We may collect information about your
              company, including company name, address, industry, and business
              contacts.
            </li>
            <li>
              Usage Data: Information on how you interact with the Platform,
              including IP address, browser type, pages visited, and usage
              patterns.
            </li>
          </ul>
        </RegularText>
        <RegularText>
          <PrivacyTitle>2. Use of Information</PrivacyTitle>Raprod uses the
          information collected for various purposes, including:{" "}
          <ul>
            <li>Providing and maintaining the Platform and Services</li>
            <li>Processing transactions and managing accounts</li>
            <li>
              Communicating with users regarding updates, promotions, and
              support
            </li>
            <li>Improving the Platform and developing new features</li>
            <li>Ensuring security and preventing fraud</li>
            <li>Complying with legal obligations</li>
          </ul>
        </RegularText>
        <RegularText>
          <PrivacyTitle>3. Sharing of Information</PrivacyTitle>We may share
          your information with:{" "}
          <ul>
            <li>
              Service Providers: Third parties who assist us in providing the
              Platform and Services, such as payment processors, hosting
              providers, and email service providers.
            </li>
            <li>
              Business Partners: Trusted partners for marketing, promotions, and
              analytics.
            </li>
            <li>
              Legal Requirements: When required by law or to protect the rights,
              property, or safety of Raprod, our users, or others.
            </li>
            <li>
              Business Transfers: In connection with any merger, sale of company
              assets, financing, or acquisition of all or a portion of our
              business to another company.
            </li>
          </ul>
        </RegularText>
        <RegularText>
          <PrivacyTitle>4. Security of Information</PrivacyTitle> We implement
          appropriate technical and organizational measures to protect your
          personal information from unauthorized access, use, alteration, or
          disclosure. However, no method of transmission over the internet or
          electronic storage is completely secure, and we cannot guarantee
          absolute security.
        </RegularText>
        <RegularText>
          <PrivacyTitle>5. Data Retention</PrivacyTitle> We retain your personal
          information only as long as necessary to fulfill the purposes for
          which it was collected and to comply with legal obligations. When we
          no longer need your personal information, we will securely delete or
          anonymize it.
        </RegularText>
        <RegularText>
          <PrivacyTitle>6. Your Rights</PrivacyTitle> Depending on your
          jurisdiction, you may have the following rights regarding your
          personal information:
          <ul>
            <li>Access to and a copy of your personal information</li>
            <li>Rectification of inaccurate or incomplete information</li>
            <li>Deletion of your personal information</li>
            <li>Restriction of or objection to processing</li>
            <li>Data portability</li>
            <li>Withdrawal of consent</li>
          </ul>
          To exercise these rights, please contact us at{" "}
          <TextBold>support@raprod.io</TextBold>.
        </RegularText>
        <RegularText>
          <PrivacyTitle>7. Changes to this Privacy Policy</PrivacyTitle>Raprod
          reserves the right to update or modify this Privacy Policy at any
          time. We will notify you of any material changes by posting the new
          Privacy Policy on the Sites and updating the “Last updated” date. Your
          continued use of the Platform and Services after any changes signify
          your acceptance of the updated Privacy Policy.
        </RegularText>
        <RegularText>
          <PrivacyTitle>8. Contact Us</PrivacyTitle>If you have any questions or
          concerns about this Privacy Policy or our data practices, please
          contact us at: Raprod Inc. Email: support@raprod.io Thank you for
          trusting Raprod with your personal information. We are committed to
          protecting your privacy and providing a secure and reliable platform
          for your business needs.
        </RegularText>
      </Container>
      <SiteFooter />
    </>
  );
};

import React from "react";
import styled from "@emotion/styled";

import HandShakeIcon from "../Assets/svg/icons/handshake.svg";
import MoneyIcon from "../Assets/svg/icons/money.svg";
import SearchIcon from "../Assets/svg/icons/search.svg";

const Container = styled.div`
  background-color: #edf4ff;
  align-self: flex-start;
  color: #003188;
  padding: 20px 30px 40px;
  width: 100%;
  box-sizing: border-box;
`;

const TitleText = styled.div`
  font-size: 32px;
  margin-bottom: 24px;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  color: #333333;
`;

export const Services = () => {
  return (
    <Container>
      <TitleText>Our Services</TitleText>
      <ListContainer>
        <img src={SearchIcon} alt="search icon" />
        <p>
          Search platform to find verified manufacturers, suppliers and
          distributors.
        </p>
      </ListContainer>
      <ListContainer
        style={{
          marginTop: "32px",
          marginBottom: "32px",
        }}
      >
        <img src={HandShakeIcon} alt="handshake icon" />
        <p>
          Create customized contracts based on your unique manufacturing needs.
        </p>
      </ListContainer>
      <ListContainer>
        <img src={MoneyIcon} alt="money icon" />
        <p>
          Receive transparent quotes and pricing for your unique business needs
          (*coming soon)
        </p>
      </ListContainer>
    </Container>
  );
};

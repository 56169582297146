import React from "react";
import styled from "@emotion/styled";

import { PageTitle } from "../UIElements/Text";
import { BuyerLayout } from "./Layout";

const ContentContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 20px 0px 20px;
`;

export const BuyerOrders = () => {
  return (
    <BuyerLayout>
      <ContentContainer>
        <PageTitle>Orders</PageTitle>
      </ContentContainer>
    </BuyerLayout>
  );
};

import React from "react";
import styled from "@emotion/styled";

import moneyIcon from "../Assets/svg/icons/money.svg";
import ProcessImage from "../Assets/svg/process.svg";
import ThumbUpIcon from "../Assets/svg/icons/thumbup.svg";
import PdfIcon from "../Assets/svg/icons/pdf.svg";
import SearchIcon from "../Assets/svg/icons/search.svg";

const Container = styled.div`
  align-self: flex-start;
  color: #003188;
  padding: 20px;
  width: 90%;
  box-sizing: border-box;
  margin-bottom: 48px;
`;

const TitleText = styled.p`
  font-size: 32px;
`;

const ImageContainer = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const ProcessList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const ListText = styled.p`
  color: #003188;
  margin: 0px;
`;

export const Process = () => {
  return (
    <Container>
      <TitleText>Our process</TitleText>
      <Row>
        <ImageContainer>
          <img src={ProcessImage} alt="process" />
        </ImageContainer>
        <div>
          <ProcessList>
            <img src={SearchIcon} alt="handshake icon" />
            <ListText>Search manufacturers</ListText>
          </ProcessList>
          <ProcessList
            style={{
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            <img src={ThumbUpIcon} alt="thumbsup icon" />
            <ListText>Find the perfect match</ListText>
          </ProcessList>
          <ProcessList
            style={{
              marginBottom: "32px",
            }}
          >
            <img src={PdfIcon} alt="pdf icon" />
            <ListText>Send your requirement</ListText>
          </ProcessList>
          <ProcessList>
            <img src={moneyIcon} alt="money icon" />
            <div>
              <ListText>Receive transparent pricing</ListText>
              <ListText>*coming soon</ListText>
            </div>
          </ProcessList>
        </div>
      </Row>
    </Container>
  );
};

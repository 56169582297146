import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import buyerLogo from "../../Assets/svg/buyer.svg";
import manufacturerLogo from "../../Assets/svg/manufacturer.svg";
import { PrimaryButton } from "../../UIElements";

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ManufacturerCard = styled.div`
  background: #fff6ee;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: stretch;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);

  @media (min-width: 992px) {
    width: 400px;
    margin-left: 24px;
  }
`;

const BuyerCard = styled.div`
  background: #f2f7ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  text-align: center;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  @media (min-width: 992px) {
    width: 400px;
  }
`;

const CardTitle = styled.p`
  color: #4e4e4e;
  font-size: 30px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 40px;
`;

const CardList = styled.li`
  color: #4e4e4e;
  font-size: 16px;
  padding-left: 10px;
  margin: 10px 0;
`;

const NotesContainer = styled.div`
  display: flex;
  text-align: left;
`;

const StyledLink = styled(Link)`
  color: #001d32;
  text-decoration: none;
`;

export const Options = () => {
  const navigate = useNavigate();
  const goToRegister = (userType) => {
    navigate("/signup", {
      state: { type: userType },
    });
  };
  return (
    <Container>
      <BuyerCard>
        <CardTitle>FOR BUYERS</CardTitle>
        <img src={buyerLogo} alt="buyerLogo" />
        <NotesContainer>
          <ul>
            <CardList>
              Search through global network of verified manufacturers
            </CardList>
            <CardList>Receive competitive pricing for your RFQ's</CardList>
            <CardList>Review manufacturers by projects accomplished</CardList>
          </ul>
        </NotesContainer>
        <PrimaryButton onClick={() => goToRegister("buyer")}>
          Register as buyer
        </PrimaryButton>
      </BuyerCard>
      <ManufacturerCard>
        <CardTitle>FOR MANUFACTURERS</CardTitle>
        <Row>
          <img src={manufacturerLogo} alt="manufacturer logo" />
        </Row>
        <NotesContainer>
          <ul>
            <CardList>Connect with global verified customers</CardList>
            <CardList>
              Access requests for quotes (RFQs) from verified buyers and
              businesses
            </CardList>
            <CardList>Attract more high-quality leads</CardList>
          </ul>
        </NotesContainer>
        <PrimaryButton
          onClick={() => goToRegister("manufacturer")}
          style={{ backgroundColor: "#F79031", color: "#000" }}
        >
          Register as manufacturer
        </PrimaryButton>
      </ManufacturerCard>
    </Container>
  );
};

import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../UIElements/Button";

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #f5f5f5;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const ProductDetails = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 8px 0;
`;

const Text = styled.p`
  margin: 0;
  color: #666;
`;

const SideActions = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const NewIndicator = styled.div`
  display: flex;
  align-items: center;
  color: green;
  font-weight: bold;

  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

export const ProductCard = ({ product }) => {
  const navigate = useNavigate();

  const handleReviewClick = () => {
    navigate(`/review/${product.id}`);
  };

  return (
    <Card>
      <Image src={product.image} alt={product.name} />
      <ProductDetails>
        <Title>{product.name}</Title>
        <Text>Products: {product.products}</Text>
        <Text>Services: {product.services}</Text>
        <Text>Quantity: {product.quantity}</Text>
        <Text>Product needed location: {product.locations}</Text>
      </ProductDetails>
      <SideActions>
        {product.isNew && <NewIndicator>New</NewIndicator>}
        <PrimaryButton onClick={handleReviewClick}>Review</PrimaryButton>
      </SideActions>
    </Card>
  );
};

import React from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  InputBox,
  PrimaryButton,
  SecondaryButton,
  SelectInputBox,
} from "../../UIElements";
import { SubTitle } from "../../UIElements/Text";
import { COUNTRY_LIST } from "../../../constants/countries";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  & div {
    flex: 1;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const Checkbox = styled.input`
  margin-right: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const ErrorText = styled.p`
  color: #b3261e;
  font-size: 12px;
  text-align: left;
  margin: 5px 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-direction: flex-start;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const validationSchema = Yup.object().shape({
  address1: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  zipcode: Yup.string().required("Required"),
  connectTerms: Yup.bool().oneOf([true], "Must accept terms and conditions"),
  successStory: Yup.bool().oneOf([true], "Must accept terms and conditions"),
});

export const ShippingDetailsRfq = (props) => {
  const handleSubmit = async (formValues) => {
    props.handleFormSubmit(formValues);
  };

  return (
    <Container>
      <SubTitle>Shipping details</SubTitle>
      <Formik
        enableReinitialize
        initialValues={props.shippingInfo}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          isSubmitting,
        }) => (
          <Form>
            <InputBox
              name="address1"
              label="Address line 1"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address1}
              isInvalid={touched.address1 && errors.address1}
              errorMessage={touched.address1 && errors.address1}
              placeholderText=""
              isRequired
            />
            <InputBox
              name="address2"
              label="Address line 2"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address2}
              isInvalid={touched.address2 && errors.address2}
              errorMessage={touched.address2 && errors.address2}
              style={{ marginTop: "24px", marginBottom: "24px" }}
            />
            <InputRow>
              <SelectInputBox
                name="country"
                label="Country"
                value={values.country}
                defaultValue={values.country}
                onSelectionChange={handleChange}
                onBlur={handleBlur}
                touched={touched.country}
                isInvalid={touched.country && errors.country}
                errorMessage={touched.country && errors.country}
                defaultOption="Select your country"
                optionList={COUNTRY_LIST}
                isRequired
              />
              <InputBox
                name="state"
                label="State"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.state}
                isInvalid={touched.state && errors.state}
                errorMessage={touched.state && errors.state}
                placeholderText="state"
                isRequired
              />
            </InputRow>
            <InputRow>
              <InputBox
                name="city"
                label="City"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                isInvalid={touched.city && errors.city}
                errorMessage={touched.city && errors.city}
                placeholderText="city"
                isRequired
              />
              <InputBox
                name="zipcode"
                label="Zipcode"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zipcode}
                isInvalid={touched.zipcode && errors.zipcode}
                errorMessage={touched.zipcode && errors.zipcode}
                placeholderText="zipcode"
                isRequired
              />
            </InputRow>

            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                name="connectTerms"
                checked={values.connectTerms}
                onChange={handleChange}
                invalid={touched.connectTerms && errors.connectTerms}
              />
              <label htmlFor="connectTerms">
                I agree that Raprod will send me quotes and connect me to the
                manufacturer i choose to work with and dealing with the
                manufacturer is the sole responsibility of the buyer.
              </label>
            </CheckboxWrapper>
            {touched.connectTerms && errors.connectTerms && (
              <ErrorText>{errors.connectTerms}</ErrorText>
            )}
            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                name="successStory"
                checked={values.successStory}
                onChange={handleChange}
                invalid={touched.successStory && errors.successStory}
              />
              <label htmlFor="successStory">
                Raprod may choose to showcase the success story of products
                manufactured by the manufacturer for the buyer
              </label>
            </CheckboxWrapper>
            {touched.successStory && errors.successStory && (
              <ErrorText>{errors.successStory}</ErrorText>
            )}
            <ButtonWrapper>
              <SecondaryButton onClick={props.handleBack}>Back</SecondaryButton>
              {/* <SecondaryButton>Cancel</SecondaryButton> */}
              <PrimaryButton> Submit RFQ</PrimaryButton>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
`;

const SelectInput = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.invalid ? "#B3261E" : "#535354")};
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  box-shadow: ${(props) => (props.invalid ? "0 0 0 1px #ff0000" : "none")};
`;

const LabelText = styled.label`
  text-align: left;
  margin-bottom: 5px;
  padding-left: 5px;

  ${(props) =>
    props.isRequired &&
    css`
      &::after {
        content: " *";
        color: red;
      }
    `}
`;

const ErrorText = styled.p`
  color: #b3261e;
  font-size: 12px;
  text-align: left;
  margin: 0;
`;

export const SelectInputBox = (props) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    props.onSelectionChange(event);
  };

  return (
    <InputContainer css={css(props.style)}>
      <LabelText isRequired={props.isRequired}>{props.label}</LabelText>

      <SelectInput
        name={props.name}
        id={props.name}
        invalid={props.isInvalid}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={handleInputChange}
      >
        {props.defaultOption && <option value="">{props.defaultOption}</option>}
        {props.optionList.map((country) => (
          <option key={country.label} value={country.value}>
            {country.label}
          </option>
        ))}
      </SelectInput>
      {props.errorMessage && <ErrorText>{props.errorMessage}</ErrorText>}
    </InputContainer>
  );
};

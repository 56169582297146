import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { confirmSignUp } from "aws-amplify/auth";
import textLogo from "../../Assets/svg/textLogo.svg";
import { Button, InputBox } from "../../UIElements";

const Container = styled.div`
  background-color: #f1f7ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 32px;
  width: 400px;
  text-align: center;
`;

const Title = styled.h1`
  color: #001d32;
  font-size: 30px;
  text-decoration: none;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
`;

const Text = styled.p`
  color: #001d32;
  font-size: 16px;
  text-decoration: none;
  font-weight: 400;
  margin: 0;
  margin-bottom: 32px;
`;

export const ConfirmSignup = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userName, userType } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");

  const handleVerifySignup = async (event) => {
    event.preventDefault();
    if (!verificationCode) {
      return setVerificationCodeError("Verification code is required.");
    }
    setIsLoading(true);
    try {
      await confirmSignUp({
        username: userName,
        confirmationCode: verificationCode,
      });
      console.log("ConfirmSignup success, route to login");
      setVerificationCodeError("");
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      setVerificationCodeError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Logo src={textLogo} alt="raprod logo" />
      <Card>
        <Title>Enter your verification code</Title>
        <Text>Please check your email for verification code</Text>
        <form onSubmit={handleVerifySignup}>
          <InputBox
            type="text"
            onTextChange={setVerificationCode}
            isInvalid={verificationCodeError}
            errorMessage={verificationCodeError}
            placeholderText="Enter verification code"
          />
          <Button isLoading={isLoading}>
            {isLoading ? "Verify..." : "Verify"}
          </Button>
        </form>
      </Card>
    </Container>
  );
};

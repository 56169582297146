import React, { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Tooltip } from "@mui/material";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, Outlet } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FaWpforms, FaPeopleArrows } from "react-icons/fa";
import { RiLogoutCircleRLine, RiAccountBoxLine } from "react-icons/ri";
import { RiHome2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import logoRap from "../Assets/img/logo1.png";
import useLogout from "../../libs/authentication/hooks/useLogout";
import { _get } from "../../api/apiClient";
// import outputs from "../../amplify/amplify_output.json";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: `#15212E`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 30,
  },
  menuButtonClose: {
    marginLeft: 15,
    marginRight: 15,
    outline: "none",
    display: "flex",
    alignItems: "center",
  },
  menuTitle: {
    marginLeft: 15,
    fontSize: "20px",
  },
  hide: {
    display: "none",
  },
  drawer: {
    backgroundColor: `#15212E`,
    color: "#fff",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: `#15212E`,
    color: "#fff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: `#15212E`,
    color: "#fff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    minHeight: "64px",
    //justifyContent: "left",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#F4F6F9",
    height: "100vh",
    //top: 500,
  },
}));

// Amplify.configure(outputs);

export default function MiniDrawer() {
  const classes = useStyles();
  // const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [isProfileIncomplete, setIsProfileIncomplete] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const logout = useLogout();
  const handleLogout = async () => {
    localStorage.clear();
    // logout();
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.log("sign out error:", error);
    }
  };

  // const fetchUser = (token) => {
  //   axios
  //     .get("https://dev.raprod.io/api/v1/manufacturers", {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((response) => {
  //       console.log("re", response);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       error.response.data.detail ===
  //       "Manufacturer details not provided by user"
  //         ? navigate("/home/manuProfileCreate")
  //         : console.log();
  //     });
  // };
  const fetchUser = async () => {
    try {
      const response = await _get("/manufacturers");
      setIsProfileIncomplete(false);
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response?.data.detail ===
        "Manufacturer details not provided by user"
      ) {
        setIsProfileIncomplete(true);
        navigate("/home/manuProfileCreate");
      } else {
        setIsProfileIncomplete(false);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      fetchUser();
    }
  }, [localStorage.getItem("accessToken")]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <Avatar
              alt="logo"
              src={logoRap}
              // style={{ backgroundColor: `#f7ede2` }}
            />
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div
            onClick={handleDrawerClose}
            className={clsx(classes.menuButtonClose, {})}
          >
            <Avatar alt="logo" src={logoRap} />
            <div className={classes.menuTitle}>Raprod</div>
          </div>
        </div>

        <Divider />
        <List>
          <ListItem
            button
            selected={selectedIndex === 0}
            component={Link}
            to="/home"
            state={{ isProfileIncomplete: isProfileIncomplete }}
            onClick={(event) => {
              handleListItemClick(event, 0);
            }}
          >
            <Tooltip title="Home">
              <ListItemIcon>
                <RiHome2Line
                  style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                  size={25}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Home</ListItemText>
          </ListItem>
          <ListItem
            button
            selected={selectedIndex === 1}
            component={Link}
            to="/home/manuProfileCreate"
            onClick={(event) => {
              handleListItemClick(event, 1);
            }}
          >
            <Tooltip title="Create profile">
              <ListItemIcon>
                <RiAccountBoxLine
                  style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                  size={28}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Create profile</ListItemText>
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === 2}
            component={Link}
            to="/home/manuDirectory"
            onClick={(event) => {
              handleListItemClick(event, 2);
            }}
          >
            <Tooltip title="Directory">
              <ListItemIcon>
                <FaWpforms
                  size={25}
                  style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Directory</ListItemText>
          </ListItem>
          <ListItem
            button
            selected={selectedIndex === 3}
            component={Link}
            to="/home/profile"
            onClick={(event) => {
              handleListItemClick(event, 3);
            }}
          >
            <Tooltip title="Profile">
              <ListItemIcon>
                <CgProfile
                  size={25}
                  style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Profile</ListItemText>
          </ListItem>
          <ListItem
            button
            selected={selectedIndex === 4}
            component={Link}
            to="/buyer/search"
            onClick={(event) => {
              handleListItemClick(event, 4);
            }}
          >
            <Tooltip title="Switch to buyer">
              <ListItemIcon>
                <FaPeopleArrows
                  size={25}
                  style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Switch to buyer</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List style={{ marginTop: `auto` }}>
          <ListItem button onClick={handleLogout}>
            <Tooltip title="Logout">
              <ListItemIcon>
                <RiLogoutCircleRLine
                  size={25}
                  style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Outlet />
      </main>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// import Check from "@material-ui/icons/Check";
// import SettingsIcon from "@material-ui/icons/Settings";
// import GroupAddIcon from "@material-ui/icons/GroupAdd";
// import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FaRegBuilding } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import { MdOutlineLocalShipping } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";
import { _get, _post } from "../../api/apiClient";

//Component Import
import OrgInfo from "./subFormManu/OrgInfo";
import ContactInfo from "./subFormManu/ContactInfo";
import Supplies from "./subFormManu/Supplies";
import Certifications from "./subFormManu/Certifications";
import { Alert } from "../UIElements";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundImage: `linear-gradient(#3F76BF, #3F76BF)`,
    },
  },
  completed: {
    "& $line": {
      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundImage: `linear-gradient(#3F76BF, #3F76BF)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    //boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    backgroundColor: `#3F76BF`,
  },
  completed: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    backgroundColor: `#3F76BF`,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <FaRegBuilding size={20} />,
    2: <MdOutlineLocalShipping size={25} />,
    3: <MdContactPhone size={20} />,
    4: <TbCertificate size={25} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
  },

  stepperTitle: {
    fontSize: 15,
    fontFamily: `Montserrat`,
    color: `#15212E `,
  },
  successContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
}));

export default function CreateManuProfile() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [orgInfo, setOrgInfo] = useState({
    orgName: "",
    orgDescription: "",
    orgAddressOne: "",
    orgAddressTwo: "",
    orgAddressCity: "",
    orgAddressState: "",
    orgAddressCountry: "",
    orgAddressZipcode: "",
    orgRegistrationNumber: "",
    orgImportCode: "",
    orgExportCode: "",
  });
  const [orgContactInfo, setOrgContactInfo] = useState({
    orgContactFirstName: "",
    orgContactLastName: "",
    orgPhoneNumber: "",
    orgEmailID: "",
    orgMailAddressOne: "",
    orgMailAddressTwo: "",
    orgMailAddressCity: "",
    orgMailAddressState: "",
    orgMailAddressCountry: "",
    orgMailAddressZipcode: "",
    orgSocialMedia: "",
    orgWebsite: "",
  });
  const [orgSupplies, setOrgSupplies] = useState({
    productManuCapability: "",
    manuServices: "",
    distributionCenter: "",
    warehouses: "",
    equipments: "",
    companySize: "",
    turnoverTime: "",
    industriesServed: "",
  });
  const [orgAccolades, setOrgAccolades] = useState({
    orgCertification: "",
    orgAwards: "",
    orgLOR: "",
  });
  const [isProfileCreationSuccess, setIsProfileCreationSuccess] =
    useState(false);
  const steps = getSteps();

  useEffect(() => {
    const getProfileData = async () => {
      try {
        const { data } = await _get("/manufacturers");
        const dataOrgInfo = {
          orgName: data.ManufacturerName,
          orgDescription: data.Description,
          orgAddressOne: data.AddressLineOne,
          orgAddressTwo: data.AddressLineTwo,
          orgAddressCity: data.City,
          orgAddressState: data.State,
          orgAddressCountry: data.Country,
          orgAddressZipcode: data.Zipcode,
          orgRegistrationNumber: data.RegistrationNumber,
          orgImportCode: data.ImportNumber,
          orgExportCode: data.ExportNumber,
        };
        const contactInfo = data.ContactInfo;
        const dataContactInfo = {
          orgContactFirstName: contactInfo.FirstName,
          orgContactLastName: contactInfo.LastName,
          orgPhoneNumber: contactInfo.PhoneNumber,
          orgEmailID: contactInfo.EmailID,
          orgMailAddressOne: contactInfo.AddressLineOne,
          orgMailAddressTwo: contactInfo.AddressLineTwo,
          orgMailAddressCity: contactInfo.City,
          orgMailAddressState: contactInfo.State,
          orgMailAddressCountry: contactInfo.Country,
          orgMailAddressZipcode: contactInfo.ZipCode,
          orgSocialMedia: contactInfo.SocialMedia,
          orgWebsite: contactInfo.WebsiteLink,
        };
        const dataOrgSupplies = {
          productManuCapability:
            data.ProductManufacturingCapability.filter(Boolean).join(", "),
          manuServices: data.ServicesProvided.filter(Boolean).join(", "),
          distributionCenter:
            data.DistributionCenters.filter(Boolean).join(", "),
          equipments: data.Equipments.filter(Boolean).join(", "),
          companySize: data.CompanySize,
          turnoverTime: data.TurnoverTime,
          industriesServed: data.IndustriesServed.filter(Boolean).join(", "),
        };
        const dataOrgAccolades = {
          orgCertification: data.Certifications.filter(Boolean).join(", "),
          orgAwards: data.Awards.filter(Boolean).join(", "),
          orgLOR: data.Recommendations.filter(Boolean).join(", "),
        };
        setOrgInfo(dataOrgInfo);
        setOrgSupplies(dataOrgSupplies);
        setOrgContactInfo(dataContactInfo);
        setOrgAccolades(dataOrgAccolades);
      } catch (error) {
        console.error("get Manufacturer profile info:", error);
      }
    };
    getProfileData();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const navigateToProfile = () => {
    navigate("/home/profile");
  };

  const handleSubmit = async (orgAccoladesValues) => {
    const ManuProfile = {
      ManufacturerName: orgInfo?.orgName,
      Description: orgInfo?.orgDescription,
      Country: orgInfo?.orgAddressCountry,
      State: orgInfo?.orgAddressState,
      City: orgInfo?.orgAddressCity,
      AddressLineOne: orgInfo?.orgAddressOne,
      AddressLineTwo: orgInfo?.orgAddressTwo,
      Zipcode: orgInfo?.orgAddressZipcode,
      RegistrationNumber: orgInfo?.orgRegistrationNumber,
      ImportNumber: orgInfo?.orgImportCode,
      ExportNumber: orgInfo?.orgExportCode,
      TurnoverTime: orgSupplies?.turnoverTime,
      URL: orgContactInfo?.orgWebsite,
      ProductManufacturingCapability:
        orgSupplies?.productManuCapability.split(","),
      ServicesProvided: orgSupplies?.manuServices.split(","),
      IndustriesServed: orgSupplies?.industriesServed.split(","),
      DistributionCenters:
        orgSupplies?.distributionCenter.length < 1
          ? []
          : orgSupplies?.distributionCenter.split(","),
      Equipments:
        orgSupplies?.equipments.length < 1
          ? []
          : orgSupplies?.equipments.split(","),
      Certifications:
        orgAccoladesValues?.orgCertification.length < 1
          ? []
          : orgAccoladesValues?.orgCertification.split(","),
      Awards:
        orgAccoladesValues?.orgAwards.length < 1
          ? []
          : orgAccoladesValues?.orgAwards.split(","),
      Recommendations:
        orgAccoladesValues?.orgLOR.length < 1
          ? []
          : orgAccoladesValues?.orgLOR.split(","),
      ContactInfo: {
        FirstName: orgContactInfo?.orgContactFirstName,
        LastName: orgContactInfo?.orgContactLastName,
        PhoneNumber: orgContactInfo?.orgPhoneNumber,
        EmailID: orgContactInfo?.orgEmailID,
        AddressLineOne: orgContactInfo?.orgMailAddressOne,
        AddressLineTwo: orgContactInfo?.orgMailAddressTwo,
        City: orgContactInfo?.orgMailAddressCity,
        State: orgContactInfo?.orgMailAddressState,
        Country: orgContactInfo?.orgMailAddressCountry,
        ZipCode: orgContactInfo?.orgMailAddressZipcode,
        SocialMedia: orgContactInfo?.orgSocialMedia,
        WebsiteLink: orgContactInfo?.orgWebsite,
      },
      Draft: false,
    };

    const result = JSON.parse(JSON.stringify(ManuProfile), (key, value) =>
      value === "" ? null : value
    );

    try {
      await _post("/manufacturers", result);
      setErrorMessage("");
      setIsProfileCreationSuccess(true);
    } catch (error) {
      setErrorMessage(error.message);
      console.error("create manufacturer profile error:", error);
    }
  };

  function getSteps() {
    return [
      "Organization Information",
      "Manufacturing Capability",
      "Contact Information",
      "Accolades",
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <OrgInfo
            handleBack={handleBack}
            handleNext={handleNext}
            submitOrgInfo={setOrgInfo}
            orgInfo={orgInfo}
          />
        );
      case 1:
        return (
          <Supplies
            handleBack={handleBack}
            handleNext={handleNext}
            submitOrgSupplies={setOrgSupplies}
            orgSupplies={orgSupplies}
          />
        );
      case 2:
        return (
          <ContactInfo
            handleBack={handleBack}
            handleNext={handleNext}
            submitOrgContactInfo={setOrgContactInfo}
            orgContactInfo={orgContactInfo}
          />
        );
      case 3:
        return (
          <Certifications
            handleBack={handleBack}
            handleNext={handleNext}
            submitAccolades={setOrgAccolades}
            onSubmit={handleSubmit}
            orgAccolades={orgAccolades}
          />
        );
      case 4:
        return (
          <div className={classes.successContainer}>
            Submitting information...
          </div>
        );
      default:
        return "Unknown step";
    }
  }
  return (
    <div className={classes.root}>
      <Stepper
        style={{ backgroundColor: `transparent` }}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {errorMessage && (
          <div className={classes.successContainer}>
            <Alert type="error" message={errorMessage} />
          </div>
        )}
        {isProfileCreationSuccess && !errorMessage ? (
          <div className={classes.successContainer}>
            <Typography className={classes.instructions}>
              Registration successful.
            </Typography>
            <Button onClick={navigateToProfile} className={classes.button}>
              Go to Profile
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, handleNext, handleBack)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { updatePassword, getCurrentUser } from "aws-amplify/auth";
import textLogo from "../Assets/svg/textLogo.svg";
import { Button, PasswordInput } from "../UIElements";
import { PasswordValidator } from "../../helpers/utils";

const Container = styled.div`
  background-color: #f1f7ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 32px;
  width: 400px;
  text-align: center;
`;

const Title = styled.h1`
  color: #001d32;
  font-size: 30px;
  text-decoration: none;
  font-weight: 500;
  margin: 0;
  margin-bottom: 32px;
`;

export const UpdatePassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  const handleConfirmPassword = async (event) => {
    event.preventDefault();
    if (!PasswordValidator(newPassword)) {
      return setNewPasswordError(
        "Password must be at least eight characters, include one uppercase letter, one lowercase letter, one number, and one special character."
      );
    }
    setIsLoading(true);
    try {
      const user = await getCurrentUser();
      await updatePassword({
        user: user,
        newPassword: newPassword,
      });
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Logo src={textLogo} alt="raprod logo" />
      <Card>
        <Title>It's time to update the password</Title>
        <form onSubmit={handleConfirmPassword}>
          <PasswordInput
            onTextChange={setNewPassword}
            isInvalid={newPasswordError}
            errorMessage={newPasswordError}
            style={{ marginTop: "16px", marginBottom: "32px " }}
            placeholderText="New password"
          />
          <Button isLoading={isLoading}>
            {isLoading ? "Submit..." : "Submit"}
          </Button>
        </form>
      </Card>
    </Container>
  );
};

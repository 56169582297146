/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FaFileUpload } from "react-icons/fa";

const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f6ff; /* Light blue background */
  border: 2px dotted #0f68ec; /* Dotted blue border */
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 8px;
  position: relative;
`;

const Input = styled.input`
  display: none;
`;

const UploadText = styled.p`
  color: #000;
  font-size: 16px;
  margin-top: 16px;
`;

const ImagePreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff5252;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #ff1744;
  }
`;

const LabelText = styled.label`
  text-align: left;
  margin-bottom: 5px;
  padding-left: 5px;

  ${(props) =>
    props.isRequired &&
    css`
      &::after {
        content: " *";
        color: red;
      }
    `}
`;

export const RFQImage = (props) => {
  const [image, setImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  return (
    <>
      <LabelText isRequired={props.isRequired}>{props.label}</LabelText>
      <UploadBox
        onClick={() => document.getElementById("fileInput").click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        {!image ? (
          <>
            <FaFileUpload color="#0f68ec" size="32px" />
            <UploadText>Drag and drop a file here or upload a file</UploadText>
          </>
        ) : (
          <>
            <ImagePreview src={image} alt="Preview" />
            <RemoveButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveImage();
              }}
            >
              Remove
            </RemoveButton>
          </>
        )}
      </UploadBox>
    </>
  );
};

/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
// import { FaTimes } from "react-icons/fa";

const alertStyles = {
  success: css`
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-left: 10px solid #00cc99;
    color: #155724;
  `,
  error: css`
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-left: 10px solid #eb5757;
    color: #721c24;
    font-size: 12px;
  `,
  warning: css`
    background-color: #fff3cd;
    border-color: #ffeeba;
    border-left: 10px solid #f2c94c;
    color: #856404;
  `,
  info: css`
    background-color: #d1ecf1;
    border-color: #bee5eb;
    color: #0c5460;
  `,
};

const AlertContainer = styled.div`
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
`;

// const CloseIcon = styled.div`
//   right: 10px;
//   cursor: pointer;
//   font-size: 16px;
// `;

export const Alert = ({ type = "info", message, onClose }) => {
  return (
    <AlertContainer css={alertStyles[type]}>
      {message}
      {/* <CloseIcon onClick={onClose}>
        <FaTimes />
      </CloseIcon> */}
    </AlertContainer>
  );
};

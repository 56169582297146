import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Header } from "../Landing/Header";
import { SiteFooter } from "../Landing/Footer";
import { PageTitle, RegularText } from "../UIElements/Text";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 50px 20px;
`;

const CenterContainer = styled.div`
  display: flex;
  align-self: center;
`;

const TermsTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

const TextBold = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  color: #285de7;
  text-decoration: none;
  font-weight: 500;
`;

export const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <Container>
        <CenterContainer>
          <PageTitle>Terms of Service</PageTitle>
        </CenterContainer>
        <RegularText>
          Raprod Terms and Conditions Last updated: August 8, 2024
        </RegularText>
        <RegularText>
          <TermsTitle>1. Introduction</TermsTitle> Welcome to Raprod, a B2B
          e-commerce platform operated by Raprod Inc. ("Raprod," "we," "us," or
          "our"). Raprod connects product manufacturers with buyers through our
          online marketplace available at raprod.io (the "Platform"). These
          Terms and Conditions ("Terms") govern your access to and use of the
          Platform and the services, features, functionality, content,
          materials, and offers provided by Raprod (collectively, the
          "Services"). By accessing or using the Platform or Services, you agree
          to be bound by these Terms. If you do not agree with these Terms, you
          should not use the Platform or Services.
        </RegularText>
        <RegularText>
          <TermsTitle>2. Changes to These Terms</TermsTitle> We may modify these
          Terms at any time in our sole discretion. We will notify you of any
          material changes by posting the updated Terms on the Platform and
          updating the "Last updated" date above. Your continued use of the
          Platform or Services after the changes are posted constitutes your
          acceptance of the modified Terms.
        </RegularText>
        <RegularText>
          <TermsTitle>3. Privacy</TermsTitle> Please review our{" "}
          <StyledLink to={`/privacy`} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </StyledLink>{" "}
          to understand our practices regarding your personal information.
        </RegularText>
        <RegularText>
          <TermsTitle>4. Account Terms</TermsTitle> To use certain Services, you
          must register for an account ("Account"). You agree to provide
          accurate, current, and complete information during the registration
          process and to update such information as necessary. You are
          responsible for maintaining the confidentiality of your Account
          credentials and for all activities that occur under your Account.
          Notify us immediately of any unauthorized use of your Account.
        </RegularText>
        <RegularText>
          <TermsTitle>5. Services</TermsTitle> Raprod offers various services
          through the Platform, including:
          <ul>
            <li>Manufacturer directory</li>
            <li>Request for Quote (RFQ) process</li>
            <li>Order management tools</li>
            <li>Transaction services</li>
          </ul>
          You must be at least 18 years old to use the Services. We reserve the
          right to refuse access to the Services to anyone at any time for any
          reason.
        </RegularText>
        <RegularText>
          <TermsTitle>6. Paid Membership Subscription Services</TermsTitle>
          Raprod offers paid membership subscriptions ("Paid Subscriptions") on
          a monthly or annual basis. By enrolling in a Paid Subscription, you
          agree to pay the applicable fees and to be bound by these Terms. Paid
          Subscriptions renew automatically unless canceled in accordance with
          the cancellation policy described below. Subscription fees are
          non-refundable. You may cancel your Paid Subscription by contacting us
          at <TextBold>support@raprod.io</TextBold> at least 30 days before the
          end of the subscription term.
        </RegularText>
        <RegularText>
          <TermsTitle>7. Free Trial Offer</TermsTitle>From time to time, we may
          offer free trials of our Paid Subscription ("Free Trial Offer"). By
          registering for a Free Trial Offer, you agree to the Terms and consent
          to automatic enrollment in a Paid Subscription unless canceled before
          the end of the free trial period.
        </RegularText>
        <RegularText>
          <TermsTitle>8. User Content</TermsTitle>By submitting any content to
          Raprod, including communications, photos, data, information, RFQs,
          reviews, or other materials ("User Content"), you grant Raprod a
          non-exclusive, royalty-free, perpetual, and irrevocable right to use,
          reproduce, modify, distribute, and display such User Content in
          connection with the Platform and Services.
        </RegularText>
        <RegularText>
          <TermsTitle>9. Prohibited Activities</TermsTitle>You agree not to
          engage in any prohibited activities, including but not limited to:
          <ul>
            <li>Reverse engineering the Platform</li>
            <li>Engaging in spamming or phishing</li>
            <li>Misrepresenting your identity</li>
            <li>Scraping or data mining</li>
            <li>Posting offensive or inappropriate content</li>
            <li>Introducing viruses or malware</li>
            <li>Infringing on intellectual property rights</li>
            <li>Overloading the Platform's resources</li>
            <li>Reselling or sublicensing access to the Platform</li>
          </ul>
          Violating any applicable laws We reserve the right to take appropriate
          action, including terminating your access to the Platform, if you
          violate these Terms.
        </RegularText>
        <RegularText>
          <TermsTitle>10. Termination</TermsTitle>We may terminate your Account
          and access to the Services at any time for any reason. You may
          terminate your Paid Subscription by contacting us at{" "}
          <TextBold>support@raprod.io</TextBold>. Termination does not entitle
          you to a refund of any prepaid fees.
        </RegularText>
        <RegularText>
          <TermsTitle>11. Intellectual Property</TermsTitle>The Platform,
          Services, and all related content and materials are owned by Raprod or
          its licensors and are protected by intellectual property laws. You are
          granted a limited, non-exclusive license to access and use the
          Platform and Services for their intended purposes, subject to these
          Terms.
        </RegularText>
        <RegularText>
          <TermsTitle>12. Indemnification</TermsTitle>You agree to indemnify and
          hold Raprod, its affiliates, and their respective directors, officers,
          employees, and agents harmless from any claims, liabilities, damages,
          losses, and expenses arising out of your use of the Platform or
          Services, your violation of these Terms, or your infringement of any
          third-party rights.
        </RegularText>
        <RegularText>
          <TermsTitle>13. Limitation of Liability</TermsTitle>To the fullest
          extent permitted by law, Raprod shall not be liable for any indirect,
          incidental, special, consequential, or punitive damages, or any loss
          of profits or revenues, whether incurred directly or indirectly, or
          any loss of data, use, goodwill, or other intangible losses resulting
          from
          <ul>
            <li>your use or inability to use the Platform or Services;</li>
            <li>
              any unauthorized access to or use of our servers and/or any
              personal information stored therein;
            </li>
            <li>
              any interruption or cessation of transmission to or from the
              Platform; or
            </li>
            <li>
              any bugs, viruses, trojan horses, or the like that may be
              transmitted to or through the Platform by any third party.
            </li>
          </ul>
        </RegularText>
        <RegularText>
          <TermsTitle>14. Governing Law</TermsTitle>These Terms and your use of
          the Platform and Services are governed by the laws of the State of
          North Carolina, USA, without regard to its conflict of law principles.
        </RegularText>
        <RegularText>
          <TermsTitle>15. Dispute Resolution</TermsTitle>Any disputes arising
          out of or relating to these Terms or the Platform and Services shall
          be resolved through binding arbitration in North Carolina, USA, in
          accordance with the rules of the American Arbitration Association. The
          arbitration shall be conducted in English and the arbitrator's
          decision shall be final and binding. You waive the right to
          participate in any class action or representative proceeding.
        </RegularText>
        <RegularText>
          <TermsTitle>16. Entire Agreement</TermsTitle>These Terms constitute
          the entire agreement between you and Raprod regarding your use of the
          Platform and Services and supersede all prior agreements and
          understandings, whether written or oral.
        </RegularText>
        <RegularText>
          <TermsTitle>17. Contact Information</TermsTitle>If you have any
          questions about these Terms or the Platform and Services, please
          contact us at support@raprod.io. Thank you for choosing Raprod. We
          look forward to serving you. Sincerely, The Raprod Team www.raprod.io
        </RegularText>
      </Container>
      <SiteFooter />
    </>
  );
};

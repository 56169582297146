import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import styled from "@emotion/styled";
import { PrimaryButton, SecondaryButton } from "../UIElements";
import { Header } from "../Landing/Header";
import { SiteFooter } from "../Landing/Footer";

const validationSchema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  subject: yup.string().required("Subject is required"),
  description: yup.string().required("Description is required"),
});

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px;
`;

const FormField = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled(Field)`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const TextArea = styled(Field)`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 100px;
  box-sizing: border-box;
`;

const ErrorText = styled.div`
  color: rgb(204, 51, 64);
  margin-top: 0.5rem;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

export const ContactUsForm = () => {
  const navigate = useNavigate();
  const initialValues = {
    fullName: "",
    email: "",
    subject: "",
    description: "",
  };

  const goToHome = () => navigate("/");
  const onSubmit = (values) => {
    console.log("Form data", values);
    // Implement your email sending logic here
  };

  return (
    <>
      <Header />
      <ContactFormContainer>
        <h2>Contact Us</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form style={{ width: "100%" }}>
              <FormField>
                <Label htmlFor="fullName">Full Name</Label>
                <Input type="text" id="fullName" name="fullName" />
                <ErrorMessage name="fullName" component={ErrorText} />
              </FormField>

              <FormField>
                <Label htmlFor="email">Email</Label>
                <Input type="email" id="email" name="email" />
                <ErrorMessage name="email" component={ErrorText} />
              </FormField>

              <FormField>
                <Label htmlFor="subject">Subject</Label>
                <Input type="text" id="subject" name="subject" />
                <ErrorMessage name="subject" component={ErrorText} />
              </FormField>

              <FormField>
                <Label htmlFor="description">Description</Label>
                <TextArea as="textarea" id="description" name="description" />
                <ErrorMessage name="description" component={ErrorText} />
              </FormField>

              <SubmitButtonContainer>
                <SecondaryButton onClick={goToHome}>Cancel</SecondaryButton>
                <PrimaryButton type="submit">Send</PrimaryButton>
              </SubmitButtonContainer>
            </Form>
          )}
        </Formik>
      </ContactFormContainer>
      <SiteFooter />
    </>
  );
};

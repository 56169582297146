import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { FaRegStar } from "react-icons/fa";

import VerifiedIcon from "../Assets/svg/icons/verified.svg";
import DefaultUpload from "../Assets/svg/defaultUpload.svg";
import { SecondaryButton } from "../UIElements";

const Container = styled.div`
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }
`;

const Logo = styled.img`
  width: 145px;
  height: 145px;
`;

const Info = styled.div`
  margin-left: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  margin-bottom: 8px;
`;

const Verified = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  @media (max-width: 768px) {
    align-self: center;
  }
`;

const IconBox = styled.span`
  margin-right: 8px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-left: 32px;
  @media (max-width: 768px) {
    margin-left: 0px;
    gap: 16px;
  }
`;

const Ratings = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
`;

export const ManufacturerInfo = ({ company }) => {
  const navigate = useNavigate();
  const goToManufacturerProfile = () => {
    navigate(`/buyer/manufacturer?manufacturerId=${company.ManufacturerId}`);
  };

  return (
    <Container>
      {company.logo ? (
        <Logo src={company.logo} alt={`${company.title} logo`} />
      ) : (
        <img src={DefaultUpload} alt="company logo" />
      )}
      <Info>
        <Row>
          <strong>{company.ManufacturerName}</strong>
        </Row>
        <Row>Products: {company.ProductManufacturingCapability}</Row>
        <Row>Services: {company.ServicesProvided}</Row>
        {/* <Row>Export value for last 12 months: {company.value}</Row> */}
        <Row>
          Location: {company.City}, {company.State}, {company.Country}
        </Row>
      </Info>
      <Verified>
        <IconBox>
          <img src={VerifiedIcon} alt="verification icon" />
        </IconBox>
        <span>Raprod verified</span>
      </Verified>
      <Actions>
        <Ratings>
          <FaRegStar color={"#000000"} />
          5.0 rating
        </Ratings>
        <SecondaryButton onClick={goToManufacturerProfile}>
          Connect
        </SecondaryButton>
      </Actions>
    </Container>
  );
};

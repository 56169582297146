/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid ${(props) => (props.invalid ? "#B3261E" : "#535354")};
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  box-shadow: ${(props) => (props.invalid ? "0 0 0 1px #ff0000" : "none")};
  &::placeholder {
    color: #919191;
  }
`;

const LabelText = styled.label`
  text-align: left;
  margin-bottom: 5px;
  padding-left: 5px;

  ${(props) =>
    props.isRequired &&
    css`
      &::after {
        content: " *";
        color: red;
      }
    `}
`;

const ErrorText = styled.p`
  color: #b3261e;
  font-size: 12px;
  text-align: left;
  margin: 0;
`;

export const InputBox = (props) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    props.onTextChange && props.onTextChange(event.target.value);
    props.onChange && props.onChange(event);
  };

  return (
    <InputContainer css={css(props.style)} className={props.className}>
      <LabelText isRequired={props.isRequired}>{props.label}</LabelText>
      <Input
        placeholder={props.placeholderText || ""}
        id={props.name}
        name={props.name}
        onChange={handleInputChange}
        value={inputValue || props.value}
        invalid={props.isInvalid}
        type={props.type || "text"}
      />
      {props.errorMessage && <ErrorText>{props.errorMessage}</ErrorText>}
    </InputContainer>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const FooterContainer = styled.footer`
  background-color: #003188;
  box-sizing: border-box;
  color: #fff;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;

const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 48px;
`;

const LinkText = styled.p`
  cursor: pointer;
`;

const CopyrightText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

export const SiteFooter = () => {
  const navigate = useNavigate();
  const goToPrivacy = () => navigate("/privacy");
  const goToTerms = () => navigate("/terms-of-service");
  return (
    <FooterContainer>
      <Footer>
        <FooterColumn>
          <p>
            <strong>Quick Links</strong>
          </p>
          <p>FAQs</p>
          <p>For manufacturers</p>
          <p>For buyers</p>
          <p>Our services</p>
          <p>Pricing</p>
        </FooterColumn>
        <FooterColumn>
          <p>
            <strong>Company</strong>
          </p>
          <p>About us</p>
          <LinkText onClick={() => navigate("/contactUs")}>Contact Us</LinkText>
        </FooterColumn>
        <FooterColumn>
          <p>
            <strong>Compliance</strong>
          </p>
          <p>Accessibility Statement</p>
          <LinkText onClick={goToPrivacy}>Privacy Policy</LinkText>
          <LinkText onClick={goToTerms}>Terms of Use</LinkText>
        </FooterColumn>
      </Footer>
      <CopyrightText>Copyright &copy; Raprod 2024</CopyrightText>
    </FooterContainer>
  );
};

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
// Components imports
import Homepage from "../Components/Home/Homepage";
import ProfileManufacture from "../Components/Profile/Manufacture/ProfileManufacture";
import CreateManuProfile from "../Components/ProfileCreate/CreateManuProfile";
import { BuyerProfile } from "../Components/Buyer/BuyerProfile";
import { BuyerSearch } from "../Components/Buyer/BuyerSearch";
import { BuyerOrders } from "../Components/Buyer/BuyerOrders";
import { BuyerRFQs } from "../Components/Buyer/BuyerRFQs";
import { SignUpOptions } from "../Components/Authentication/SignUpOption";
import DirectoryContainer from "../Components/Directory/DirectoryContainer";
import { AuthProvider } from "../libs/contexts/authetication/authContext";
import PrivateRoutes from "./privateRoutes";
import Dashboard from "../Components/Dashboard/dashboard";
import { LoginCard } from "../Components/Authentication/LogIn/Forms/LoginCard";
import { SignUpCard } from "../Components/Authentication/SignUp/SignupCard";
import { ResetPassowrdCard } from "../Components/Authentication/ResetPassword";
import { amplifyConfig } from "../amplify/amplify_output";
import { UpdatePassword } from "../Components/Authentication/UpdatePassword";
import { ConfirmSignup } from "../Components/Authentication/SignUp/ConfirmSignup";
import { LandingPage } from "../Components/Landing/LandingPage";
import { ContactUsForm } from "../Components/Support/ContactUs";
import { TermsAndConditions } from "../Components/Support/TermsAndConditions";
import { PrivacyPolicy } from "../Components/Support/PrivacyPolicy";
import { BuyerManufactureProfile } from "../Components/Buyer/ManufacturerProfile";
import { ManufacturerHome } from "../Components/Manufacturer/Home";
import { ManufacturerDirectory } from "../Components/Manufacturer/Directory";
import { ManufacturerProfile } from "../Components/Manufacturer/Profile";
import { ManufacturerCreateProfile } from "../Components/Manufacturer/CreateProfile";
import { PublicRoutes } from "./publicRoutes";

Amplify.configure(amplifyConfig);

export default function Main() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signUpOptions" element={<SignUpOptions />} />
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<LoginCard />} />
            <Route path="/signup" element={<SignUpCard />} />
          </Route>
          <Route path="/confirmSignup" element={<ConfirmSignup />} />
          <Route path="/resetPassword" element={<ResetPassowrdCard />} />
          <Route path="/contactUs" element={<ContactUsForm />} />
          <Route path="/terms-of-service" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/updatePassword" element={<UpdatePassword />} />
            <Route path="/buyer/profile" element={<BuyerProfile />} />
            <Route path="/buyer/search" element={<BuyerSearch />} />
            <Route path="/buyer/orders" element={<BuyerOrders />} />
            <Route path="/buyer/rfqs" element={<BuyerRFQs />} />
            <Route
              path="/buyer/manufacturer"
              element={<BuyerManufactureProfile />}
            />
            {/* <Route path="/home" element={<Homepage />}> */}
            {/* <Route path="/home" element={<Dashboard />} /> */}
            {/* <Route path="/home/profile" element={<ProfileManufacture />} /> */}
            {/* <Route
              path="/home/manuProfileCreate"
              element={<CreateManuProfile />}
            /> */}
            {/* <Route
              path="/home/manuDirectory"
              element={<DirectoryContainer />}
            /> */}
            {/* </Route> */}
            <Route path="/home" element={<ManufacturerHome />} />
            <Route path="/home/profile" element={<ManufacturerProfile />} />
            <Route
              path="/home/manuProfileCreate"
              element={<ManufacturerCreateProfile />}
            />
            <Route
              path="/home/manuDirectory"
              element={<ManufacturerDirectory />}
            />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

import { useState, useEffect } from "react";
import { _get, _patch, _post } from "../../../api/apiClient";

export const useBuyerProfile = () => {
  const [buyerProfileInfo, setBuyerProfileInfo] = useState({
    companyName: "",
    email: "",
    companyWebsite: "",
    productDetails: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    socialMediaHandles: [{ AccountType: "", Link: "" }],
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isProfileCreationSuccess, setIsProfileCreationSuccess] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getBuyerProfileData = async () => {
    try {
      const { data } = await _get("/buyers");
      setBuyerProfileInfo(mapProfileData(data));
      setIsEdit(Boolean(data?.BuyerName));
    } catch (error) {
      console.error("get buyer profile info:", error);
    }
  };

  const handleSubmit = async (formValues) => {
    const buyerProfileValues = mapFormValues(formValues);
    try {
      isEdit
        ? await _patch("/buyers", buyerProfileValues)
        : await _post("/buyers", buyerProfileValues);
      setIsProfileCreationSuccess(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message);
      console.error("create buyer profile error:", error);
    }
  };

  useEffect(() => {
    getBuyerProfileData();
  }, []);

  return {
    buyerProfileInfo,
    handleSubmit,
    errorMessage,
    isEdit,
    isProfileCreationSuccess,
  };
};

const mapProfileData = (data) => {
  const AddressInfo = data?.Address;
  return {
    companyName: data.BuyerName,
    companyWebsite: data.Website,
    email: data.EmailId,
    productDetails: data.InterestedProductServices,
    address1: AddressInfo.AddressLineOne,
    address2: AddressInfo.AddressLineTwo,
    country: AddressInfo.Country,
    state: AddressInfo.State,
    city: AddressInfo.City,
    zipcode: AddressInfo.Zipcode,
    socialMediaHandles: data.SocialMedias || [{ AccountType: "", Link: "" }],
  };
};

const mapFormValues = (formValues) => ({
  BuyerName: formValues.companyName,
  EmailId: formValues.email,
  Website: formValues.companyWebsite,
  InterestedProductServices: formValues.productDetails,
  SocialMedias: formValues.socialMediaHandles,
  Address: {
    AddressLineOne: formValues.address1,
    AddressLineTwo: formValues.address2,
    City: formValues.city,
    State: formValues.state,
    Country: formValues.country,
    Zipcode: formValues.zipcode,
  },
});

/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { signOut } from "aws-amplify/auth";
import {
  FaChevronRight,
  FaChevronLeft,
  FaSearch,
  FaBox,
  FaBook,
  FaUser,
  FaSignOutAlt,
} from "react-icons/fa";

const Container = styled.div`
  display: flex;
  align-self: stretch;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  align-items: flex-start;
  width: ${(props) => (props.open ? "160px" : "60px")};
  height: 100%;
  background-color: #f8f8f8;
  transition: width 0.3s;
  overflow: hidden;
`;

const NavItem = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px;
  text-decoration: none;
  color: #656565;
  cursor: pointer;
  &:hover {
    background-color: #e6efff;
  }
  &:hover span.tooltip {
    display: ${(props) => (props.open ? "none" : "block")};
  }
  &.active {
    background-color: #e6efff;
    color: #003188;
  }
`;

const Label = styled.span`
  margin-left: 10px;
  font-size: 16px;
  display: ${(props) => (props.open ? "inline" : "none")};
`;

const Tooltip = styled.span`
  display: none;
  position: absolute;
  left: 60px;
  background-color: #2c3e50;
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
`;

const LogoutButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px;
  text-decoration: none;
  color: #656565;
  cursor: pointer;
  &:hover {
    background-color: #e6efff;
  }
  &:hover span.tooltip {
    display: ${(props) => (props.open ? "none" : "block")};
  }
`;

const ArrowItem = styled(LogoutButton)`
  justify-content: ${(props) => (props.open ? "center" : "flex-start")};
`;

export const NavBar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleNav = () => {
    setOpen(!open);
  };

  const handleLogout = async () => {
    localStorage.clear();
    // logout();
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.log("sign out error:", error);
    }
  };

  return (
    <Container>
      <Nav open={open}>
        <ArrowItem open={open} onClick={toggleNav}>
          {open ? (
            <FaChevronLeft color={"#000000"} />
          ) : (
            <FaChevronRight color={"#000000"} />
          )}
          <Tooltip className="tooltip">Open</Tooltip>
        </ArrowItem>
        <NavItem to="/buyer/search" open={open}>
          <FaSearch />
          <Label open={open}>Search</Label>
          <Tooltip className="tooltip">Search</Tooltip>
        </NavItem>
        <NavItem to="/buyer/orders" open={open}>
          <FaBox />
          <Label open={open}>Orders</Label>
          <Tooltip className="tooltip">Orders</Tooltip>
        </NavItem>
        <NavItem to="/buyer/rfqs" open={open}>
          <FaBook />
          <Label open={open}>RFQ’s</Label>
          <Tooltip className="tooltip">RFQ’s</Tooltip>
        </NavItem>
        <NavItem to="/buyer/profile" open={open}>
          <FaUser />
          <Label open={open}>Profile</Label>
          <Tooltip className="tooltip">Profile</Tooltip>
        </NavItem>
        <LogoutButton onClick={handleLogout} open={open}>
          <FaSignOutAlt color={"#000000"} />
          <Label open={open}>Logout</Label>
          <Tooltip className="tooltip">Logout</Tooltip>
        </LogoutButton>
      </Nav>
    </Container>
  );
};

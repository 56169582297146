import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./formStyles.module.css";
import Grid from "@material-ui/core/Grid";
import { PrimaryButton } from "../../UIElements";

export default function OrgInfo(props) {
  const validation = Yup.object().shape({
    orgName: Yup.string().required("required"),
    orgDescription: Yup.string().required("required"),
    orgAddressOne: Yup.string().required("required"),
    orgAddressCity: Yup.string().required("required"),
    orgAddressState: Yup.string().required("required"),
    orgAddressCountry: Yup.string().required("required"),
    orgAddressZipcode: Yup.string().required("required"),
  });

  const handleSubmit = (values) => {
    props.submitOrgInfo(values);
    props.handleNext();
  };

  return (
    <div className={styles.formCard}>
      <Formik
        enableReinitialize
        initialValues={props.orgInfo}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.formStyles}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgName">
                        Organization Name{" "}
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `40em` }}
                      className={styles.textInput}
                      name="orgName"
                      placeholder="xyz inc."
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="orgName"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgDescription">
                        Organization Description
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      component="textarea"
                      className={styles.textAreaInput}
                      name="orgDescription"
                      placeholder="Describe the products and services your company offers"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="orgDescription"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgAddressOne">
                        Address line one
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgAddressOne"
                      placeholder="110 W 39th St"
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgAddressTwo">Address line two</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgAddressTwo"
                      placeholder="suit no 123"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgAddressCity">
                        City
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgAddressCity"
                      placeholder="New York City"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgAddressState">
                        State
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgAddressState"
                      placeholder="New York"
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgAddressCountry">
                        Country
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}

                      className={styles.textInput}
                      name="orgAddressCountry"
                      placeholder="USA"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgAddressZipcode">
                        Zipcode
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgAddressZipcode"
                      placeholder="12121"
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgRegistrationNumber">
                        Registration number
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgRegistrationNumber"
                      placeholder="1234536789"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgImportCode">Import/Export code</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgImportCode"
                      placeholder="1234536789"
                    />
                  </div>
                </Grid>

                {/* <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgExportCode">Export code</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgExportCode"
                      placeholder="1234536789"
                    />
                  </div>
                </Grid> */}
              </Grid>
            </div>
            <div className={styles.buttonContainer}>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                Next
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

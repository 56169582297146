import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Formik, Form, FieldArray } from "formik";

// import { ImageUpload } from "./UploadImage";
import {
  Alert,
  InputBox,
  PrimaryButton,
  SecondaryButton,
  SelectInputBox,
} from "../UIElements";
import { AddressForm } from "./Profile/Address";
import { validationSchema } from "./Profile/validation";
import { useBuyerProfile } from "./Profile/useBuyerProfile";
import { PageTitle, SubTitle } from "../UIElements/Text";
import { BuyerLayout } from "./Layout";

const ContentContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 20px 0px 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: "center";
  gap: 16px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
`;

const LinkedText = styled.div`
  font-size: 14px;
  color: #003188;
  cursor: pointer;
`;

const RemoveButton = styled.div`
  color: red;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const socialMediaOptions = [
  { value: "linkedin", label: "LinkedIn" },
  { value: "instagram", label: "Instagram" },
  { value: "twitter", label: "Twitter" },
  { value: "facebook", label: "Facebook" },
];

export const BuyerProfile = () => {
  const {
    buyerProfileInfo,
    handleSubmit,
    errorMessage,
    isEdit,
    isProfileCreationSuccess,
  } = useBuyerProfile();
  const navigate = useNavigate();

  const goToHome = (event) => {
    event.preventDefault();
    navigate("/buyer/search");
  };

  return (
    <BuyerLayout>
      <ContentContainer>
        <PageTitle>Company profile (Buyer)</PageTitle>
        <SubTitle>Company details</SubTitle>
        {errorMessage && (
          <MessageContainer>
            <Alert type="error" message={errorMessage} />
          </MessageContainer>
        )}
        {isProfileCreationSuccess && !errorMessage ? (
          <MessageContainer>
            <Alert
              type="success"
              message={
                <>
                  <SubTitle>
                    {isEdit
                      ? "Profile has been successfully updated!"
                      : "Registration successful!"}
                  </SubTitle>
                  <PrimaryButton onClick={goToHome}>Go to Search</PrimaryButton>
                </>
              }
            />
          </MessageContainer>
        ) : (
          <Formik
            enableReinitialize
            initialValues={buyerProfileInfo}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
            }) => (
              <Form>
                <InputBox
                  name="companyName"
                  label="Company name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyName}
                  isInvalid={touched.companyName && errors.companyName}
                  errorMessage={touched.companyName && errors.companyName}
                  placeholderText="Company name"
                  isRequired
                />
                <InputBox
                  name="productDetails"
                  label="Products and services your company is interested in buying"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.productDetails}
                  isInvalid={touched.productDetails && errors.productDetails}
                  errorMessage={touched.productDetails && errors.productDetails}
                  placeholderText="Eg: Metal bolts, Plastic bushing, Forging of existing products etc"
                  style={{ marginBottom: "16px", marginTop: "16px" }}
                  isRequired
                />
                <InputBox
                  name="companyWebsite"
                  label="Company website"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyWebsite}
                  isInvalid={touched.companyWebsite && errors.companyWebsite}
                  errorMessage={touched.companyWebsite && errors.companyWebsite}
                  placeholderText="https://www.companyname.com"
                />
                <InputBox
                  name="email"
                  label="Email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={touched.email && errors.email}
                  errorMessage={touched.email && errors.email}
                  placeholderText="email"
                  style={{ marginBottom: "24px", marginTop: "16px" }}
                  isRequired
                />
                <AddressForm
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
                <FieldArray name="socialMediaHandles">
                  {({ insert, remove, push }) => (
                    <>
                      {values.socialMediaHandles.map((handle, index) => (
                        <Fragment key={index}>
                          <div>
                            <SelectInputBox
                              name={`socialMediaHandles[${index}].AccountType`}
                              label="Select social media account type"
                              value={
                                values.socialMediaHandles[index].AccountType
                              }
                              defaultValue={
                                values.socialMediaHandles[index].AccountType
                              }
                              onSelectionChange={handleChange}
                              onBlur={handleBlur}
                              touched={touched.socialMediaHandles}
                              isInvalid={
                                errors.socialMediaHandles &&
                                errors.socialMediaHandles[index] &&
                                errors.socialMediaHandles[index].AccountType &&
                                touched.socialMediaHandles &&
                                touched.socialMediaHandles[index] &&
                                touched.socialMediaHandles[index].AccountType
                              }
                              errorMessage={
                                errors.socialMediaHandles &&
                                errors.socialMediaHandles[index] &&
                                errors.socialMediaHandles[index].AccountType &&
                                touched.socialMediaHandles &&
                                touched.socialMediaHandles[index] &&
                                touched.socialMediaHandles[index].AccountType
                              }
                              defaultOption="Select your socialMedia"
                              optionList={socialMediaOptions}
                              style={{ marginTop: "24px", marginBottom: "4px" }}
                            />
                            <InputBox
                              name={`socialMediaHandles[${index}].Link`}
                              label="Link"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.socialMediaHandles[index].Link}
                              isInvalid={
                                errors.socialMediaHandles &&
                                errors.socialMediaHandles[index] &&
                                errors.socialMediaHandles[index].Link &&
                                touched.socialMediaHandles &&
                                touched.socialMediaHandles[index] &&
                                touched.socialMediaHandles[index].Link
                              }
                              errorMessage={
                                errors.socialMediaHandles &&
                                errors.socialMediaHandles[index] &&
                                errors.socialMediaHandles[index].Link &&
                                touched.socialMediaHandles &&
                                touched.socialMediaHandles[index] &&
                                touched.socialMediaHandles[index].Link
                              }
                              placeholderText="social media link"
                              style={{ marginBottom: "8px" }}
                            />
                          </div>
                          {index > 0 && (
                            <RemoveButton onClick={() => remove(index)}>
                              Remove
                            </RemoveButton>
                          )}
                        </Fragment>
                      ))}
                      {values.socialMediaHandles.length < 4 && (
                        <LinkedText
                          onClick={() => {
                            push({ AccountType: "", Link: "" });
                          }}
                        >
                          Add social media links
                        </LinkedText>
                      )}
                    </>
                  )}
                </FieldArray>

                {/* <ImageUpload /> */}
                <InputRow style={{ marginTop: "24px" }}>
                  <SecondaryButton onClick={goToHome}>Cancel</SecondaryButton>
                  <PrimaryButton type="submit">Save</PrimaryButton>
                </InputRow>
              </Form>
            )}
          </Formik>
        )}
      </ContentContainer>
    </BuyerLayout>
  );
};

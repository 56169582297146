import React from "react";
import styled from "@emotion/styled";
import { SiteFooter } from "../Landing/Footer";
import { ManufacturerHeader } from "../SiteHeader/ManufacturerHeader";
import { ManufacturerNavBar } from "../Navigation/ManufacturerNavbar";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
const VStack = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FooterSpacer = styled.div`
  margin-top: 96px;
`;

export const ManufacturerLayout = ({ children }) => {
  return (
    <>
      <ManufacturerHeader />
      <Container>
        <ManufacturerNavBar />
        <VStack>
          {children}
          <FooterSpacer />
        </VStack>
      </Container>
      <SiteFooter />
    </>
  );
};

export const BUYER_QUESTION_LIST = [
  {
    question: "How do I find the right manufacturer for my product?",
    answer:
      "Finding the right manufacturer on Raprod is straightforward. Use our advanced search filters to narrow down manufacturers based on your specific product requirements, industry, location, and other preferences. Additionally, you can view detailed profiles of manufacturers, including their past projects, client reviews, and ratings to make an informed decision.",
  },
  {
    question:
      "What is the process for submitting a manufacturing request on RaProd?",
    answer: `Submitting a manufacturing request on Raprod involves a few simple steps: \n\nCreate an Account: Sign up or log in to your Raprod account. \n\nSubmit Request: Fill out the manufacturing request form with details about your product, specifications, quantity, and any other relevant information. \n\nReceive Quotes: Manufacturers will review your request and provide quotes. \nEvaluate Quotes: Compare the quotes based on cost, timeline, and manufacturer profiles. \n\nSelect Manufacturer: Choose the manufacturer that best fits your needs and proceed with the contract.`,
  },
  {
    question:
      "How do I ensure the quality and reliability of the manufacturers on RaProd?",
    answer:
      "Raprod ensures quality and reliability through a rigorous vetting process for all manufacturers listed on our platform. You can also review detailed manufacturer profiles, including certifications, previous work, client reviews, and ratings. Additionally, Raprod offers quality assurance services and regular audits to maintain high standards.",
  },
  {
    question:
      "What are the costs involved in using RaProd to get my product manufactured?",
    answer: `The costs involved include:
      \n\nBeta Users : Completely free usage
      \n\nPost Introductory Trial Period : 
      \n\nPlatform Fee: A small fee for using Raprod's platform to connect with manufacturers.
      \n\nManufacturing Costs: The price quoted by the manufacturer for producing your product.
      \n\nOptional Services: Additional fees for quality assurance, logistics, and other value-added services if required.`,
  },
  {
    question:
      "How do I handle payment and contract terms with the manufacturer?",
    answer:
      "Payments and contract terms are managed directly through the Raprod platform. Once you select a manufacturer, you can negotiate and finalize the contract terms within the platform. Raprod provides secure payment options and escrow services to ensure both parties are protected.",
  },
];

export const MANUFACTURERS_QUESTION_LIST = [
  {
    question: "How does Raprod help me find potential clients?",
    answer:
      "Raprod connects you with a wide network of potential clients actively seeking manufacturing services. By listing your services on Raprod, you can showcase your capabilities to a targeted audience, receive manufacturing requests, and respond with quotes. The platform's advanced search algorithms help match you with clients whose needs align with your expertise.",
  },
  {
    question: "What types of products and services can I offer through RaProd?",
    answer: `You can offer a diverse range of products and services on Raprod, including but not limited to:
      \n\nCustom product manufacturing
      \n\nPrototype development
      \n\nMass production
      \n\nSpecialized manufacturing processes
      \n\nProduct assembly and packaging`,
  },
  {
    question:
      "How do I communicate the quality of the products I manufacture through RaProd ?",
    answer: `Communicating product quality is key to attracting clients. On Raprod, you can:
      \n\nUpload detailed profiles with certifications, quality standards, and compliance documents.
      \n\nShowcase case studies and previous projects.
      \n\nEncourage clients to leave reviews and ratings.
      \n\nParticipate in Raprod's quality assurance program to earn additional trust badges.`,
  },
  {
    question: "What are the fees associated with using RaProd's platform ?",
    answer: `Fees for using Raprod include:
      \n\nBeta Users : Completely free usage
      \n\nPost Introductory Trial Period : 
      \n\nListing Fee: A nominal fee per year to list your manufacturing services on the platform.
      \n\nOptional Services Fees: Additional costs for premium features like highlighted listings,ads, vetting services, logistics marketing support, and advanced analytics.`,
  },
  {
    question:
      "How can I handle shipping and logistics for the products I manufacture?",
    answer: `Raprod offers integrated logistics support to streamline shipping and delivery. You can:
      \n\nPartner with Raprod's preferred logistics providers for discounted rates.
      \n\nUse the platform's logistics management tools to coordinate shipping.
      \n\nOffer end-to-end solutions to clients, including shipping and handling as part of your services.`,
  },
];

import React from "react";
import styled from "@emotion/styled";

import { SiteFooter } from "./Footer";
import { Header } from "./Header";
import { FAQs } from "./FAQs";
import { Partners } from "./Partners";
import { Services } from "./Services";
import { Process } from "./Process";
import { MainSection } from "./Main";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
`;

export const LandingPage = () => {
  return (
    <Container>
      <Header />
      <MainSection />
      <Process />
      <Services />
      <Partners />
      <FAQs />
      <SiteFooter />
    </Container>
  );
};

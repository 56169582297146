import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import Machine from "../Assets/svg/machine.svg";
import { PrimaryButton, SecondaryButton } from "../UIElements";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #003188;
  padding: 0px 20px;
  margin-top: 48px;
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ImageContainer = styled.div`
  margin-top: -48px;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`;

const MainImage = styled.img`
  width: 98%;
`;

const TitleText = styled.p`
  font-size: 48px;
  margin: 0px;
`;
const DefaultText = styled.p`
  margin: 0px;
  font-size: 16px;
`;
const LightText = styled.p`
  font-size: "24px";
  font-weight: 200;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 48px;
`;

export const MainSection = () => {
  const navigate = useNavigate();
  const goToLogin = () => navigate("/login");
  const goToManufactureSignUp = () => navigate("/signUpOptions");
  return (
    <Container>
      <TextContainer>
        <DefaultText>*Beta launch</DefaultText>
        <TitleText>Manufacturing made simple</TitleText>
        <LightText>
          Bring your product ideas to reality with Raprod. Find verified
          manufacturers, request for quotes, manufacture your products.
        </LightText>
        <Row>
          <PrimaryButton onClick={goToLogin}>Search manufacturer</PrimaryButton>
          <SecondaryButton onClick={goToManufactureSignUp}>
            Register
          </SecondaryButton>
        </Row>
      </TextContainer>
      <ImageContainer>
        <MainImage src={Machine} alt="machine" />
      </ImageContainer>
    </Container>
  );
};

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { FaSistrix } from "react-icons/fa";

import { _post } from "../../api/apiClient";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 4px 0 0 4px;
  flex-grow: 1;
`;

const SearchButton = styled.button`
  background-color: #dae7ff;
  border: 1px solid #e8e8e8;
  border-left: none;
  padding: 11px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ManufacturerSearch = ({ handleSearchChange, onResults }) => {
  const [query, setQuery] = useState(
    localStorage.getItem("buyerSearchTerm") || ""
  );

  useEffect(() => {
    if (query) {
      handleSearchChange(query);
      fetchSearchResults(query);
    }
  }, []);

  const fetchSearchResults = async (searchTerm) => {
    localStorage.setItem("buyerSearchTerm", searchTerm);
    const searchObj = {
      query: searchTerm,
      start: 10,
      size: 10,
    };

    try {
      const response = await _post("/manufacturers/search", searchObj);
      onResults(response.data.results);
    } catch (error) {
      console.error("Error fetching Buyer search results:", error);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    handleSearchChange(query);
    fetchSearchResults(query);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchChange(query);
      fetchSearchResults(query);
    }
  };

  return (
    <Container>
      <Input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Search..."
      />
      <SearchButton onClick={handleSearch}>
        <FaSistrix />
      </SearchButton>
    </Container>
  );
};

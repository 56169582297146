import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledButton = styled.button`
  background: #3f76bf;
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  width: 100%;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  animation: ${spin} 1s linear infinite;
`;

export const Button = ({ isLoading, children, ...props }) => (
  <StyledButton disabled={isLoading} {...props}>
    {isLoading && <Spinner />}
    {children}
  </StyledButton>
);

const ButtonPrimary = styled.button`
  background-color: #003188;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

export const PrimaryButton = ({ children, ...props }) => (
  <ButtonPrimary {...props}>{children}</ButtonPrimary>
);

const ButtonSecondary = styled.button`
  background-color: #ffffff;
  color: #003188;
  border: 1px solid #003188;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;
export const SecondaryButton = ({ children, ...props }) => (
  <ButtonSecondary {...props}>{children}</ButtonSecondary>
);

const ButtonAlert = styled.button`
  background-color: #b3261e;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

export const AlertButton = ({ children, ...props }) => (
  <ButtonAlert {...props}>{children}</ButtonAlert>
);

const ButtonText = styled.button`
  color: #001d32;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

export const TextButton = ({ children, ...props }) => (
  <ButtonText {...props}>{children}</ButtonText>
);

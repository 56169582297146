import React, { useEffect, useState } from "react";
import styles from "./SearchDirectory.module.css";
import { RiCloseLine } from "react-icons/ri";

export default function SearchDirectory({
  onSearch,
  setSearchQuery,
  onClearSearchResults,
}) {
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  );
  const [selectedSearch, setSelectedSearch] = useState([]);

  useEffect(() => {
    if (searchTerm && onSearch) {
      setSelectedSearch([searchTerm]);
      setSearchQuery(searchTerm);
      onSearch(searchTerm);
    }
  }, []);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event) => {
    // Perform search action here, you can pass the searchTerm to a parent component
    // or make an API call for search results
    setSelectedSearch((prevSelectedSearch) => [
      // ...prevSelectedSearch,
      searchTerm,
    ]);
    localStorage.setItem("searchTerm", searchTerm);
    setSearchQuery(searchTerm);
    if (onSearch) {
      onSearch(searchTerm);
    }
    setSearchTerm("");
  };

  const removeSearch = (index) => {
    // Create a new array without the selected search term at the given index
    // setSelectedSearch((prevSelectedSearch) =>
    //   prevSelectedSearch.filter((_, i) => i !== index)
    // );
    localStorage.setItem("searchTerm", "");
    setSelectedSearch((prevSelectedSearch) => []);
    setSearchQuery(searchTerm);
    setSearchTerm("");
    onClearSearchResults();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <div className={styles.searchContainer}>
        <div className={styles.searchWrapper}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
            className={styles.searchField}
            onKeyDown={handleKeyPress}
          />
          <button onClick={handleSearch} className={styles.searchButton}>
            Search
          </button>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <div className={styles.keywordsWrapper}>
          {selectedSearch.map((searchTerm, index) => (
            <div
              onClick={() => removeSearch(index)}
              className={styles.keyWordsButton}
            >
              {searchTerm}
              <div className={styles.closeIcon}>
                <RiCloseLine size={18} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { InputBox, SelectInputBox } from "../../UIElements";
import { BoldText, SmallText } from "../../UIElements/Text";
import { COUNTRY_LIST } from "../../../constants/countries";

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: "center";
  gap: 16px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const AddressForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
}) => (
  <>
    <BoldText>Address</BoldText>
    <SmallText>Enter registered company address </SmallText>
    <InputBox
      name="address1"
      label="Address line 1"
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.address1}
      isInvalid={touched.address1 && errors.address1}
      errorMessage={touched.address1 && errors.address1}
      placeholderText="Address line 1"
      style={{ marginBottom: "16px", marginTop: "24px" }}
      isRequired
    />
    <InputBox
      name="address2"
      label="Address line 2"
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.address2}
      isInvalid={touched.address2 && errors.address2}
      errorMessage={touched.address2 && errors.address2}
      placeholderText="Address line 2"
      style={{ marginBottom: "16px" }}
    />

    <InputRow>
      <SelectInputBox
        name="country"
        label="Country"
        value={values.country}
        defaultValue={values.country}
        onSelectionChange={handleChange}
        onBlur={handleBlur}
        touched={touched.country}
        isInvalid={touched.country && errors.country}
        errorMessage={touched.country && errors.country}
        defaultOption="Select your country"
        optionList={COUNTRY_LIST}
        style={{ marginBottom: "16px", flex: 1 }}
        isRequired
      />
      <InputBox
        name="state"
        label="State"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.state}
        isInvalid={touched.state && errors.state}
        errorMessage={touched.state && errors.state}
        placeholderText="state"
        style={{ marginBottom: "16px", flex: 1 }}
        isRequired
      />
    </InputRow>
    <InputRow>
      <InputBox
        name="city"
        label="City"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.city}
        isInvalid={touched.city && errors.city}
        errorMessage={touched.city && errors.city}
        placeholderText="city"
        style={{ flex: 1 }}
        isRequired
      />
      <InputBox
        name="zipcode"
        label="Zipcode"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.zipcode}
        isInvalid={touched.zipcode && errors.zipcode}
        errorMessage={touched.zipcode && errors.zipcode}
        placeholderText="zipcode"
        style={{ flex: 1 }}
        isRequired
      />
    </InputRow>
  </>
);

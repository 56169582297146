import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { PageTitle } from "../UIElements/Text";
import { Alert, PrimaryButton } from "../UIElements";
import { ManufacturerInfo } from "./ManufacturerInfo";
import { ManufacturerSearch } from "./SearchInput";
import { _get } from "../../api/apiClient";
import { BuyerLayout } from "./Layout";

const ContentContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 20px 0px 20px;
  width: 100%;
  box-sizing: border-box;
`;

const SearchContainer = styled.div`
  display: flex;
  margin: 32px 0;
  width: 80%;
  box-sizing: border-box;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80%;
  box-sizing: border-box;
`;

const NoResultContainer = styled.div`
  display: flex;
  justifycontent: center;
  margintop: 50px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 80%;
  box-sizing: border-box;
`;

const StyledLink = styled(Link)`
  color: #2f3032;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 500;
`;

export const BuyerSearch = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [isProfileInComplete, setIsProfileInComplete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const goToRfqs = () => navigate("/buyer/rfqs");

  const checkFields = (data) => {
    const fieldsToCheck = [
      "BuyerName",
      "InterestedProductServices",
      "EmailId",
      "Address.AddressLineOne",
      "Address.City",
      "Address.State",
      "Address.Country",
      "Address.Zipcode",
    ];

    const hasMissing = fieldsToCheck.some((field) => {
      const fieldParts = field.split(".");
      let value = data;

      for (let part of fieldParts) {
        value = value ? value[part] : null;
        if (value === null || value === undefined || value === "") {
          return true;
        }
      }
      return false;
    });

    setIsProfileInComplete(hasMissing);
  };

  useEffect(() => {
    const getBuyerProfileData = async () => {
      try {
        const { data } = await _get("/buyers");

        checkFields(data);
      } catch (error) {
        const errorMessage = error.response?.data?.detail;
        if (errorMessage === "Buyer details not provided by user") {
          setIsProfileInComplete(true);
        }
        console.error("search get buyer profile info:", error);
      }
    };
    getBuyerProfileData();
  }, []);

  const handleResults = (data) => {
    setResults(data);
  };

  return (
    <BuyerLayout>
      <ContentContainer>
        {isProfileInComplete && (
          <MessageContainer>
            <Alert
              type="warning"
              message={
                <>
                  Please complete your{" "}
                  <StyledLink to={"/buyer/profile"}>profile</StyledLink> to
                  start using the platform!
                </>
              }
            />
          </MessageContainer>
        )}
        <PageTitle>Find manufacturers</PageTitle>
        <PrimaryButton onClick={goToRfqs}>Create RFQs</PrimaryButton>
        <SearchContainer>
          <ManufacturerSearch
            onResults={handleResults}
            handleSearchChange={setSearchTerm}
          />
        </SearchContainer>
        <InfoContainer>
          {searchTerm && results.length < 1 && (
            <NoResultContainer>No results found.</NoResultContainer>
          )}
          {searchTerm &&
            results.length > 0 &&
            results.map((manufacturerInfo, index) => (
              <ManufacturerInfo
                company={manufacturerInfo}
                key={manufacturerInfo.ManufacturerId}
              />
            ))}
        </InfoContainer>
      </ContentContainer>
    </BuyerLayout>
  );
};

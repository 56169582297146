// src/CountryMultiSelect.js
import React from "react";
import Select from "react-select";
import styled from "@emotion/styled";
import { COUNTRY_LIST } from "../../constants/countries";

// Styled component for the select container
const StyledSelectContainer = styled.div`
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;

  .react-select__control {
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 5px;
    height: 46px;
  }

  .react-select__menu {
    border-radius: 10px;
  }
`;

export const CountryMultiSelect = ({
  selectedCountries,
  setSelectedCountries,
}) => {
  const handleChange = (selectedOptions) => {
    setSelectedCountries(selectedOptions);
  };

  return (
    <StyledSelectContainer>
      <Select
        isMulti
        options={COUNTRY_LIST}
        classNamePrefix="react-select-country"
        value={selectedCountries}
        onChange={handleChange}
      />
    </StyledSelectContainer>
  );
};

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Input = styled.input`
  border-radius: 10px;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  border: 1px solid ${(props) => (props.invalid ? "#B3261E" : "#535354")};
  box-shadow: ${(props) => (props.invalid ? "0 0 0 1px #ff0000" : "none")};
  &::placeholder {
    color: #919191;
  }
`;

const Icon = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const LabelText = styled.label`
  text-align: left;
  margin-bottom: 5px;
  padding-left: 5px;
  ${(props) =>
    props.isRequired &&
    css`
      &::after {
        content: " *";
        color: red;
      }
    `}
`;

const ErrorText = styled.p`
  color: #b3261e;
  font-size: 12px;
  text-align: left;
  margin: 5px 0 0;
`;

export const PasswordInput = (props) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    props.onTextChange && props.onTextChange(event.target.value);
    props.onChange && props.onChange(event);
  };

  return (
    <Container css={css(props.style)}>
      <LabelText isRequired={props.isRequired}>{props.label}</LabelText>
      <InputContainer>
        <Input
          id={props.name}
          name={props.name}
          placeholder={props.placeholderText || "Password"}
          onChange={handlePasswordChange}
          type={showPassword ? "text" : "password"}
          value={password}
          invalid={props.isInvalid}
        />
        <Icon onClick={toggleShowPassword}>
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </Icon>
      </InputContainer>
      {props.errorMessage && <ErrorText>{props.errorMessage}</ErrorText>}
    </Container>
  );
};

import React from "react";
import styled from "@emotion/styled";
import { QuestionAnswerList } from "../common/QuestionAnswerBlock";
import {
  BUYER_QUESTION_LIST,
  MANUFACTURERS_QUESTION_LIST,
} from "../../constants/questions";

const Container = styled.div`
  align-self: flex-start;
  color: #003188;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`;
const QuestionContainer = styled.div`
  background-color: #f5f9ff;
  padding: 20px;
`;
const ManuQuestionContainer = styled.div`
  background-color: #f5f9ff;
  padding: 20px;
  margin-top: 24px;
  margin-bottom: 48px;
`;

const HeaderText = styled.p`
  margin: 0px;
  font-size: 20px;
`;

export const FAQs = () => {
  return (
    <Container>
      <p style={{ fontSize: "32px", marginBottom: "32px" }}>FAQ’s</p>
      <QuestionContainer>
        <HeaderText>FOR BUYERS</HeaderText>
        <QuestionAnswerList qaList={BUYER_QUESTION_LIST} />
      </QuestionContainer>
      <ManuQuestionContainer>
        <HeaderText>FOR MANUFACTURERS</HeaderText>
        <QuestionAnswerList qaList={MANUFACTURERS_QUESTION_LIST} />
      </ManuQuestionContainer>
    </Container>
  );
};

import React from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

import { FaBullseye, FaRegFileAlt, FaRegCheckCircle } from "react-icons/fa";
import { IncompleteProfile } from "./IncompleteProfile";
import { Tabs } from "../common/Tab";
import { InfoCard } from "./InfoCard";
import { RFQList } from "./RfqList";
import { ActiveBidsList } from "./ActiveBidsList";
import { AcceptedBidsList } from "./AcceptedBidsList";

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 24px 0 24px 0;
  width: 100%;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

export default function Dashboard() {
  const location = useLocation();
  const isProfileIncomplete = location.state?.isProfileIncomplete;

  return (
    <>
      {isProfileIncomplete && <IncompleteProfile />}

      <TileContainer>
        <InfoCard label="New RFQ's" icon={<FaRegFileAlt />} count="0" isNew />
        <InfoCard label="Active Bids" icon={<FaBullseye />} count="0" />
        <InfoCard
          label="Accepted Bid(s)"
          icon={<FaRegCheckCircle />}
          count="0"
        />
      </TileContainer>

      <Tabs labels={["RFQ's", "Active Bids", "Accepted Bids"]}>
        <div>
          <RFQList />
        </div>
        <div>
          <ActiveBidsList />
        </div>
        <div>
          <AcceptedBidsList />
        </div>
      </Tabs>
    </>
  );
}

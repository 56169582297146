import React, { useState } from "react";
import styled from "@emotion/styled";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./formStyles.module.css";
import Grid from "@material-ui/core/Grid";
import {
  CountryMultiSelect,
  PrimaryButton,
  SecondaryButton,
} from "../../UIElements";

const LabelText = styled.p`
  font-size: 12px;
  margin-left: 16px;
`;

export default function Supplies(props) {
  const [selectedCountries, setSelectedCountries] = useState([]);

  const validation = Yup.object().shape({
    productManuCapability: Yup.string().required("required"),
    manuServices: Yup.string().required("required"),
    // companySize: Yup.string().required("required"),
    industriesServed: Yup.string().required("required"),
  });

  const handleSubmit = (values) => {
    props.submitOrgSupplies(values);
    props.handleNext();
  };

  return (
    <div className={styles.formCard}>
      <Formik
        enableReinitialize
        initialValues={props.orgSupplies}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.formStyles}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="productManuCapability">
                        Products Manufactured
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `40em` }}
                      className={styles.textInput}
                      name="productManuCapability"
                      placeholder="Enter list of products your company caters to or have manufactured before"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="productManuCapability"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="manuServices">
                        Manufacturing Services
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="manuServices"
                      placeholder="Enter list of services your company provides"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="manuServices"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="distributionCenter">
                        Distribution Centers
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="distributionCenter"
                      placeholder="new york, chicago, mumbai, london"
                    />
                  </div>
                </Grid>

                {/* <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="warehouses">Warehouses</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="warehouses"
                      placeholder="Example : circuit boards, wires"
                    />
                  </div>
                </Grid> */}
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="equipments">List of machinery</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="equipments"
                      placeholder="Enter the list of equipments/machineries your company has"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="companySize">Number of employees</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="companySize"
                      placeholder="Example : 10"
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="companySize"
                    />
                  </div>
                </Grid>

                {/* <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="turnoverTime">
                        Turnover Time
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="turnoverTime"
                      placeholder="Example : 2 weeks"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="turnoverTime"
                    />
                  </div>
                </Grid> */}

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="industriesServed">
                        Industries Served
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="industriesServed"
                      placeholder="automotive, construction, textile"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="industriesServed"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="industriesServed">
                        countries catered to
                      </label>
                    </div>
                    <CountryMultiSelect
                      selectedCountries={selectedCountries}
                      setSelectedCountries={setSelectedCountries}
                    />
                    <LabelText htmlFor="industriesServed">
                      Provide the list of countries your company caters to
                    </LabelText>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.buttonContainer}>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                Next
              </PrimaryButton>
              <SecondaryButton onClick={props.handleBack}>Back</SecondaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

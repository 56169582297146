import React, { useState } from "react";
import styles from "./DirectoryContainer.module.css";
import SearchDirectory from "./Search/SearchDirectory";
import List from "./List";
import { isEmpty } from "lodash";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { _post } from "../../api/apiClient";

export default function DirectoryContainer() {
  const [query, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const fetchSearches = async (searchParam) => {
    setIsLoading(true);
    setErrorMessage("");
    const start = 0;
    const size = 10;
    const searchObj = {
      query: searchParam,
      start: start,
      size: size,
    };

    try {
      const response = await _post("/manufacturers/search", searchObj);
      setSearchResults(response.data.results);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      setErrorMessage("Something went wrong. Please try again.");
    }

    // axios
    //   .post("https://dev.raprod.io/api/v1/manufacturers/search", searchObj)
    //   .then((response) => {
    //     // Handle success, you can access the response data using `response.data`
    //     setSearchResults(response.data.results);
    //     console.log("Response:", response.data.results);
    //   })
    //   .catch((error) => {
    //     // Handle error, you can access the error details using `error`
    //     console.error("Error:", error);
    //   });
  };

  const clearSearchResults = () => setSearchResults([]);

  return (
    <div className={styles.container}>
      <SearchDirectory
        setSearchQuery={setSearchQuery}
        onSearch={fetchSearches}
        onClearSearchResults={clearSearchResults}
      />
      {isEmpty(query) && (
        <div className={styles.defaultText}>
          <MdOutlineConnectWithoutContact
            className={styles.connectIcon}
            size={35}
          />
          Search products or services to connect to Manufacturers
        </div>
      )}
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          Searching...
        </div>
      )}
      {errorMessage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          {errorMessage}
        </div>
      )}
      {!isLoading && !isEmpty(query) && searchResults.length < 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          No results found.
        </div>
      )}
      {!isEmpty(query) && searchResults.length > 0 && (
        <List searchResults={searchResults} />
      )}
    </div>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { ManufacturerLayout } from "./Layout";
import Dashboard from "../Dashboard/dashboard";

const ContentContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 20px 0px 20px;
`;

export const ManufacturerHome = () => {
  return (
    <ManufacturerLayout>
      <ContentContainer>
        <Dashboard />
      </ContentContainer>
    </ManufacturerLayout>
  );
};

import React from "react";
import styled from "@emotion/styled";

import checkIcon from "../Assets/svg/icons/check.svg";
import lockIcon from "../Assets/svg/icons/lock.svg";
import syncIcon from "../Assets/svg/icons/sync.svg";
import locationIcon from "../Assets/svg/icons/location.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  color: #003188;
  padding: 20px;
  margin-top: 32px;
`;
const TitleText = styled.div`
  font-size: 32px;
  margin-bottom: 32px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
  color: #003188;
`;

const Card = styled.div`
  background-color: #f5f9ff;
  border-radius: 20px;
  padding: 20px;
  flex: 1;
`;

const CardText = styled.p`
  font-size: "16px";
  margin-top: "24px";
`;

export const Partners = () => {
  return (
    <Container>
      <TitleText>Forge trusted relationships with reliable partners.</TitleText>
      <Row>
        <Card>
          <img src={checkIcon} alt="check icon" />
          <CardText>Source direct from verified manufacturers</CardText>
        </Card>
        <Card>
          <img src={lockIcon} alt="lock icon" />
          <CardText>
            Confidential agreements to safeguard mutual interests
          </CardText>
        </Card>
        <Card>
          <img src={syncIcon} alt="sync icon" />
          <CardText>Secured exchange of design and engineering files.</CardText>
        </Card>
        <Card>
          <img src={locationIcon} alt="location icon" />
          <CardText>Geotagged manufacturing facility.</CardText>
        </Card>
      </Row>
    </Container>
  );
};

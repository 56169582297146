import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { FaBars, FaChevronDown, FaPeopleArrows } from "react-icons/fa";
import textLogo from "../Assets/svg/newTextLogo.svg";

const Container = styled.div`
  background-color: #003188;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 992px) {
    justify-content: flex-start;
  }
`;

const MenuIcon = styled(FaBars)`
  display: none;
  cursor: pointer;

  @media (max-width: 992px) {
    display: block;
    color: #fff;
  }
`;

const Logo = styled.img`
  @media (max-width: 992px) {
    margin-left: 20px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  width: 100%;
  z-index: 1;

  @media (max-width: 992px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #003188;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

const Text = styled.div`
  cursor: pointer;
  color: #fff;

  @media (max-width: 992px) {
    text-align: left;
    width: 100%;
  }
`;
const LinkText = styled.div`
  cursor: pointer;
  color: #fff;

  @media (max-width: 992px) {
    padding-left: 20px;
    text-align: left;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @media (max-width: 992px) {
    display: none;
  }
`;

const SmallScreenRow = styled.div`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  gap: 16px;
  display: flex;

  @media (min-width: 992px) {
    display: none;
  }
`;

const Divider = styled.div`
  @media (max-width: 992px) {
    height: 1px;
    background-color: #fff;
    width: 100%;
    margin: 8px 0;
  }
`;

const IconText = styled.div`
  display: flex;
  gap: 4px;
  justfiy-content: center;
  align-items: end;

  @media (max-width: 992px) {
    gap: 8px;
    justfiy-content: center;
    align-items: center;
  }
`;

const NavItem = styled.span`
  color: #fff;
  cursor: pointer;
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: #656565;
  &:hover {
    background-color: #e6efff;
  }
`;

export const BuyerHeader = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    // Close the current dropdown if clicking on the same, otherwise open the clicked one
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);
    }
  };

  const toggleDrawer = () => setIsOpen(!isOpen);
  const goToHome = () => navigate("/buyer/search");
  const switchToManufacturer = () => {
    setActiveDropdown(null); // Close dropdowns when switching
    navigate("/home");
  };

  return (
    <Container>
      <MenuIcon onClick={toggleDrawer} />
      <Logo src={textLogo} alt="logo" />
      <MenuContainer isOpen={isOpen}>
        <SmallScreenRow>
          <Text>Buyer</Text>
          {/* <Text>Account</Text> */}
          <IconText>
            <FaPeopleArrows color="white" />
            <Text onClick={switchToManufacturer}>Switch to manufacturer</Text>
          </IconText>
        </SmallScreenRow>
        <Divider />
        <LinkText onClick={goToHome}>Home</LinkText>
        {/* <LinkText>Notification</LinkText> */}
        <Row>
          <NavItem onClick={() => toggleDropdown("buyer")}>
            <IconText>
              Buyer <FaChevronDown color="white" />
            </IconText>
            <DropdownMenu isOpen={activeDropdown === "buyer"}>
              <MenuItem onClick={switchToManufacturer}>Manufacturer</MenuItem>
            </DropdownMenu>
          </NavItem>
          {/* <Text>Account</Text> */}
        </Row>
      </MenuContainer>
    </Container>
  );
};

import React from "react";
import styled from "@emotion/styled";

const Card = styled.div`
  font-family: Montserrat;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  align-items: center;
  width: 100%;
`;

const TileIcon = styled.div`
  color: #3f76bf;
  margin-right: 10px;
`;
const TileHeading = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
`;

const GreenCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-left: 10px;
  align-self: center;
`;

const TileCount = styled.div`
  display: flex;
  font-size: 32px;
  margin-top: 16px;
  font-weight: 500;
  color: #003188;
`;

export const InfoCard = ({ label, icon, count, isNew }) => {
  return (
    <Card>
      <TileHeading>
        <TileIcon>{icon}</TileIcon>
        {label}
        {isNew && <GreenCircle />}
      </TileHeading>
      <TileCount>{count}</TileCount>
    </Card>
  );
};

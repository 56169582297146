import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const token = localStorage.getItem("accessToken");

// const getSession = async () => {
//   try {
//     const session = await fetchAuthSession();
//     console.log("api session:", session);
//     console.log("api session tokens:", session.tokens);
//     return session?.tokens?.accessToken || "";
//   } catch (error) {
//     console.log("api session error: ", error);
//   }
// };
// const token = getSession();

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
  },
});
const getAccessToken = async () => {
  const session = await fetchAuthSession();

  return session.tokens?.accessToken.toString();
};

// Set the AUTH token for any request
apiClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errorMessage = error.response?.data.detail;

    if (
      errorMessage &&
      errorMessage.includes("Not authenticated") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await getAccessToken();
      return apiClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

// Define common API methods
const _get = (url, config = {}) => {
  return apiClient.get(url, config);
};

const _delete = (url, config = {}) => {
  return apiClient.delete(url, config);
};

const _put = (url, data = {}, config = {}) => {
  return apiClient.put(url, data, config);
};

const _post = (url, data = {}, config = {}) => {
  return apiClient.post(url, data, config);
};
const _patch = (url, data = {}, config = {}) => {
  return apiClient.patch(url, data, config);
};

// Export API methods
export { _get, _delete, _patch, _put, _post };

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID, //"ap-south-1_HGsknEOxo",
      userPoolClientId: process.env.REACT_APP_CLIENT_ID, //"6ej22ggnpucth20ivndf4u9p7h",
      identityPoolId: "",
      loginWith: {
        email: true,
      },
      oauth: {
        domain: "",
        scope: [],
        identityProviders: ["GOOGLE", "FACEBOOK"],
        redirectSignInUri: ["http://localhost:3000/home"],
        redirectSignOutUri: ["http://localhost:3000/home"],
        responseType: "code",
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      allowGuestAccess: true,
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
};

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import textLogo from "../Assets/svg/textLogo.svg";
import siteLogo from "../Assets/svg/logo.svg";
import {
  Alert,
  Button,
  InputBox,
  PasswordInput,
  SecondaryButton,
} from "../UIElements";
import { EmailValidator, PasswordValidator } from "../../helpers/utils";

const Container = styled.div`
  background-color: #f1f7ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 32px;
  width: 400px;
  text-align: center;
`;

const MainLogo = styled.img`
  margin-bottom: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: "center";
  gap: 16px;
  margin: 32px 0px 0px;
`;

const Text = styled.p`
  color: #001d32;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const StyledLink = styled(Link)`
  color: #001d32;
  text-decoration: none;
  margin-left: 8px;
  font-weight: 500;
`;

export const ResetPassowrdCard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [showCodeField, setShowCodeField] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const hideVerificationField = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setPasswordError("");
    setShowCodeField(false);
  };

  const handleSendResetCode = async (event) => {
    event.preventDefault();
    if (!EmailValidator(email)) {
      return setEmailError("Please enter a valid email address");
    }

    setIsLoading(true);
    setEmailError("");
    const output = await resetPassword({
      username: email,
    });
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        setSuccessMessage(`Confirmation code was sent to email`);
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        setShowCodeField(true);
        break;
      case "DONE":
        setSuccessMessage("Successfully reset the password");
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const handleConfirmCode = async (event) => {
    event.preventDefault();
    if (!PasswordValidator(password)) {
      return setPasswordError(
        "Password must be at least eight characters, include one uppercase letter, one lowercase letter, one number, and one special character."
      );
    }

    setPasswordError("");

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: confirmationCode,
        newPassword: password,
      });
      setSuccessMessage("Successfully reset the password");
      navigate("/login");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Container>
      <Logo src={textLogo} alt="raprod logo" />
      <Card>
        <MainLogo src={siteLogo} alt="raprod Logo" />
        {successMessage && !errorMessage && (
          <Alert type="success" message={successMessage} />
        )}
        {errorMessage && <Alert type="error" message={errorMessage} />}
        <InputBox
          type="email"
          onTextChange={setEmail}
          isInvalid={emailError}
          errorMessage={emailError}
          placeholderText="email"
        />
        {showCodeField && (
          <>
            <InputBox
              type="text"
              onTextChange={setConfirmationCode}
              isInvalid={emailError}
              errorMessage={emailError}
              placeholderText="Enter confirmation code"
            />
            <PasswordInput
              onTextChange={setPassword}
              isInvalid={passwordError}
              errorMessage={passwordError}
              style={{ marginTop: "16px", marginBottom: "32px " }}
            />
          </>
        )}
        {showCodeField ? (
          <Row>
            <SecondaryButton
              onClick={hideVerificationField}
              style={{ borderRadius: "40px", flex: 1 }}
            >
              Request code
            </SecondaryButton>
            <Button
              isLoading={isLoading}
              onClick={handleConfirmCode}
              style={{ flex: "1" }}
            >
              {isLoading ? "Confirm..." : "Confirm"}
            </Button>
          </Row>
        ) : (
          <Button
            isLoading={isLoading}
            onClick={handleSendResetCode}
            style={{ marginTop: "20px" }}
          >
            {isLoading ? "Sending..." : "Send code"}
          </Button>
        )}

        <Text>
          Don't have an account?{" "}
          <StyledLink to={"/signUpOptions"}>Register</StyledLink>
        </Text>
      </Card>
    </Container>
  );
};

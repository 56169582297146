import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { signUp } from "aws-amplify/auth";
import googleLogo from "../../Assets/svg/googleLogo.svg";
import facebookLogo from "../../Assets/svg/facebookLogo.svg";
import linkedInLogo from "../../Assets/svg/linkedInLogo.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styles from "./SignUp.module.css";
import { Header } from "../../Landing/Header";
import { SiteFooter } from "../../Landing/Footer";
import {
  Alert,
  InputBox,
  PasswordInput,
  PrimaryButton,
  SecondaryButton,
  SelectInputBox,
} from "../../UIElements";
import { PasswordRegex } from "../../../helpers/utils";
import { COUNTRY_LIST } from "../../../constants/countries";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  @media (min-width: 992px) {
    justify-content: flex-start;
    padding: 50px 5px;
  }
`;

const Title = styled.h1`
  color: #003188;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  margin-top: 0;
`;

const Card = styled.div`
  background-color: white;
  padding: 32px;
  text-align: center;
  @media (min-width: 768px) {
    width: 400px;
    padding: 5px;
  }
`;

const CardTitle = styled.h1`
  color: #001d32;
  font-size: 24px;
  text-decoration: none;
  font-weight: 500;
  margin: 0;
  margin-bottom: 32px;
  @media (max-width: 992px) {
    text-align: left;
    font-size: 20px;
  }
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: "center";
  gap: 16px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
  }

  &::before {
    margin-right: 0.25em;
  }

  &::after {
    margin-left: 0.25em;
  }
`;

const SocialLogos = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 24px;
`;

const Text = styled.p`
  color: #001d32;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
`;

const ErrorText = styled.p`
  color: #b3261e;
  font-size: 12px;
  text-align: left;
  margin: 5px 0 0;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const Checkbox = styled.input`
  margin-right: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const StyledLink = styled(Link)`
  color: #285de7;
  text-decoration: none;
  margin-left: 4px;
  font-weight: 500;
`;

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  companyName: "",
  country: "",
  password: "",
  reEnterPassword: "",
  terms: false,
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  password: Yup.string()
    .matches(
      PasswordRegex,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    )
    .required("Required"),
  reEnterPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  terms: Yup.bool().oneOf([true], "Must accept terms and conditions"),
});

export const SignUpCard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSigUp = async (formValues) => {
    setIsLoading(true);
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: formValues.email,
        password: formValues.password,
        options: {
          userAttributes: {
            email: formValues.email,
            given_name: formValues.firstName,
            family_name: formValues.lastName,
            "custom:company_name": formValues.companyName,
            "custom:country": formValues.country,
            "custom:user_type": state.type,
          },
        },
      });
      if (!isSignUpComplete && nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        navigate("/confirmSignup", {
          state: {
            userId: userId,
            userName: formValues.email,
            userType: state.type,
          },
        });
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
    // clearAllErrors();
    // SignUpUser();
  };

  const goToOptions = () => navigate("/signUpOptions");

  return (
    <>
      <Header />
      <Container>
        <Title>Register and transform your business with Raprod</Title>
        <Card>
          <CardTitle>
            {state.type === "buyer"
              ? "Register as a buyer"
              : "Register as a manufacturer"}
          </CardTitle>
          {errorMessage && <Alert type="error" message={errorMessage} />}

          {/* <Divider>or</Divider>
          <SocialLogos>
            <img src={googleLogo} alt="google login" />
            <img src={facebookLogo} alt="facebook login" />
            <img src={linkedInLogo} alt="linkedin login" />
          </SocialLogos>
          */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSigUp(values);
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
            }) => (
              <Form>
                <InputBox
                  name="email"
                  label="Email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={touched.email && errors.email}
                  errorMessage={touched.email && errors.email}
                  placeholderText="email"
                  isRequired
                />
                <InputRow>
                  <InputBox
                    name="firstName"
                    label="First name"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.firstName && errors.firstName}
                    errorMessage={touched.firstName && errors.firstName}
                    placeholderText="First Name"
                    className={styles.firstNameInput}
                    isRequired
                  />
                  <InputBox
                    name="lastName"
                    label="Last name"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.lastName && errors.lastName}
                    errorMessage={touched.lastName && errors.lastName}
                    placeholderText="Last Name"
                    className={styles.lastNameInput}
                    isRequired
                  />
                </InputRow>
                <InputBox
                  name="companyName"
                  label="Company name"
                  type="text"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.companyName}
                  isInvalid={touched.companyName && errors.companyName}
                  errorMessage={touched.companyName && errors.companyName}
                  placeholderText="Company Name"
                  style={{ marginBottom: "16px" }}
                  isRequired
                />
                <SelectInputBox
                  name="country"
                  label="Country"
                  value={values.country}
                  defaultValue={values.country}
                  onSelectionChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.country}
                  isInvalid={touched.country && errors.country}
                  errorMessage={touched.country && errors.country}
                  defaultOption="Select your country"
                  optionList={COUNTRY_LIST}
                  style={{ marginBottom: "16px" }}
                  isRequired
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "32px",
                  }}
                >
                  <InputRow>
                    <PasswordInput
                      name="password"
                      label="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.password && errors.password}
                      isRequired
                    />
                    <PasswordInput
                      name="reEnterPassword"
                      value={values.reEnterPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.reEnterPassword && errors.reEnterPassword
                      }
                      placeholderText="Re-Enter Password"
                      label="Re-Enter password"
                      isRequired
                    />
                  </InputRow>
                  {touched.password &&
                    touched.reEnterPassword &&
                    (errors.reEnterPassword || errors.password) && (
                      <ErrorText>
                        {errors.password || errors.reEnterPassword}
                      </ErrorText>
                    )}
                </div>
                <CheckboxWrapper>
                  <Checkbox
                    type="checkbox"
                    name="terms"
                    checked={values.terms}
                    onChange={handleChange}
                    invalid={touched.terms && errors.terms}
                  />
                  <label htmlFor="terms">
                    I agree to Raprod's
                    <StyledLink
                      to={`/terms-of-service`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Privacy Policy
                    </StyledLink>
                  </label>
                </CheckboxWrapper>
                {touched.terms && errors.terms && (
                  <ErrorText>{errors.terms}</ErrorText>
                )}
                <VStack>
                  <PrimaryButton
                    isLoading={isLoading}
                    style={{ marginBottom: "20px" }}
                    type="submit"
                  >
                    {isLoading ? "Register..." : "Register"}
                  </PrimaryButton>
                  <SecondaryButton onClick={goToOptions}>
                    Cancel
                  </SecondaryButton>
                </VStack>
              </Form>
            )}
          </Formik>
          <Text style={{ marginTop: "32px", justifyContent: "flex-start" }}>
            Have an account? <StyledLink to={`/login`}>Log In</StyledLink>
          </Text>
        </Card>
      </Container>
      <SiteFooter />
    </>
  );
};

import React from "react";
import styled from "@emotion/styled";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

// Styled components
const Container = styled.div`
  background-color: #f5f9ff;
  padding: 16px 16px 16px 0px;
  margin: 8px 0;
  border-radius: 4px;
`;
const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  /* cursor: ${({ isOpen }) => (isOpen ? "none" : "pointer")}; */
`;
const HeaderBlock = styled.div`
  color: #003188;
  font-weight: bold;
  position: relative;
  padding-left: 20px;
`;

const OpenBlock = styled.div`
  color: #003188;
  margin-top: 16px;
  padding-left: 36px;
  white-space: preserve-breaks;
`;

export const Accordion = ({ header, openBlock, isOpen }) => {
  return (
    <Container>
      <AccordionContainer>
        {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        <HeaderBlock isOpen={isOpen}>{header}</HeaderBlock>
      </AccordionContainer>
      {isOpen && <OpenBlock>{openBlock}</OpenBlock>}
    </Container>
  );
};

// export const Accordion = ({ qaList }) => {
//   return (
//     <div>
//       {qaList.map((qa, index) => (
//         <QuestionAnswerBlock
//           key={index}
//           question={qa.question}
//           answer={qa.answer}
//           isOpen={openIndex === index}
//           onClick={() => handleClick(index)}
//         />
//       ))}
//     </div>
//   );
// };

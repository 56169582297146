import styled from "@emotion/styled";

export const PageTitle = styled.h1`
  color: #000;
  font-size: 40px;
  font-weight: 400;
  margin-top: 0;
`;

export const SubTitle = styled.h2`
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
`;

export const BoldTitle = styled.h2`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
`;

export const BoldText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
`;

export const RegularText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 300;
  margin-top: 0;
`;

export const SmallText = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0;
`;

import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { fetchUserAttributes } from "aws-amplify/auth";

export const PublicRoutes = () => {
  const [userType, setUserType] = useState("loading");

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      try {
        const userAttributes = await fetchUserAttributes();

        if (userAttributes["custom:user_type"] === "buyer") {
          setUserType("buyer");
        } else if (userAttributes["custom:user_type"] === "manufacturer") {
          setUserType("manufacturer");
        } else {
          setUserType("");
        }
      } catch (error) {
        // console.log("routes session error: ", error);
        setUserType("");
        // return "";
      }
    };
    checkUserLoggedIn();
  }, []);

  if (userType === "loading") {
    return <></>;
  }

  if (userType === "buyer") {
    return <Navigate to="/buyer/search" />;
  } else if (userType === "manufacturer") {
    return <Navigate to="/home" />;
  } else {
    return <Outlet />;
  }
  // console.log("route token: ", userId);
  // return token ? <Outlet /> : <Navigate to="/" />;
};

import React from "react";
import styled from "@emotion/styled";
import { BuyerHeader } from "../SiteHeader/BuyerHeader";
import { SiteFooter } from "../Landing/Footer";
import { NavBar } from "../Navigation/Navbar";

const Container = styled.div`
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
const VStack = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FooterSpacer = styled.div`
  margin-top: 96px;
`;

export const BuyerLayout = ({ children }) => {
  return (
    <>
      <BuyerHeader />
      <Container>
        <NavBar />
        <VStack>
          {children}
          <FooterSpacer />
        </VStack>
      </Container>
      <SiteFooter />
    </>
  );
};

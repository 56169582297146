import React, { useState } from "react";
import styled from "@emotion/styled";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import textLogo from "../Assets/svg/newTextLogo.svg";

const Container = styled.div`
  background-color: #003188;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 992px) {
    justify-content: flex-start;
  }
`;

const MenuIcon = styled(FaBars)`
  display: none;
  cursor: pointer;

  @media (max-width: 992px) {
    display: block;
    color: #fff;
  }
`;

const Logo = styled.img`
  @media (max-width: 992px) {
    margin-left: 20px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  width: 100%;
  z-index: 1;

  @media (max-width: 992px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #003188;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

const Text = styled.div`
  cursor: pointer;
  color: #fff;

  @media (max-width: 992px) {
    text-align: left;
    width: 100%;
  }
`;
const LinkText = styled.div`
  cursor: pointer;
  color: #fff;

  @media (max-width: 992px) {
    padding-left: 20px;
    text-align: left;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @media (max-width: 992px) {
    display: none;
  }
`;

const SmallScreenRow = styled.div`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  gap: 16px;
  display: flex;

  @media (min-width: 992px) {
    display: none;
  }
`;

const LoginButton = styled.div`
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
`;

const Divider = styled.div`
  @media (max-width: 992px) {
    height: 1px;
    background-color: #fff;
    width: 100%;
    margin: 8px 0;
  }
`;

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => setIsOpen(!isOpen);
  const goToHome = () => navigate("/");

  const goToLogin = () => navigate("/login");
  const goToManufactureSignUp = () => navigate("/signUpOptions");

  return (
    <Container>
      <MenuIcon onClick={toggleDrawer} />
      <Logo src={textLogo} alt="logo" />
      <MenuContainer isOpen={isOpen}>
        <SmallScreenRow>
          <Text onClick={goToManufactureSignUp}>Register</Text>
          <LoginButton onClick={goToLogin}>Login</LoginButton>
        </SmallScreenRow>
        <Divider />
        <LinkText onClick={goToHome}>Home</LinkText>
        {/* <LinkText>For manufacturers</LinkText>
        <LinkText>For Buyers</LinkText>
        <LinkText>Our services</LinkText>
        <LinkText>Pricing</LinkText>
        <LinkText>About us</LinkText> */}
        <LinkText onClick={() => navigate("/contactUs")}>Contact us</LinkText>
        <Row>
          <Text onClick={goToManufactureSignUp}>Register</Text>
          <LoginButton onClick={goToLogin}>Login</LoginButton>
        </Row>
      </MenuContainer>
    </Container>
  );
};
